import EventEmitter from 'eventemitter3';

export enum NLPEditingEventType {
  None = 0,
  Start, // мотор !!!
  Stop, // стоп, снято
  TakeVideo, // переключение (эффекты переключения тут же)
  TakeAudio, // переключение звуковой дороги
  TextOverlay, // вывод текста поверх видео
  EffectOverlay, // вывод эфекта поверх видео
  MediaOverlay, // вывод медиа (видео, картинка) поверх видео
  VideoInsert, // вставка отрезка видео
  AudioInsert, // вставка звуковой дорожки
  OverlayTransform, // изменение физических размеров
  OverlayModify, // изменение цвета, содержимого и т.д.
  VideoTransform, // кроп-зум видео
  VideoModify, // яркость, контрастность, ...
  AudioModify, // громкость, муть, ...
  TakeBlack, // черный экран
  TakeMute, // пустая звуковая дорога
}

interface NLPObjectPosition {
  x: number;
  y: number;
  z?: number; // для выбора слоя
}

interface NLPObjectDimensions {
  width: number;
  height: number;
}

type NLPObjectPlacement = NLPObjectPosition & NLPObjectDimensions;

interface NLPEditingEventBase {
  order: number; // порядок применения события
  dt: number; // время применения события
  type: NLPEditingEventType; // тип события
  cloudId?: string; // id записи при заливке в облако
}

interface NLPEditingEventTake {
  camera: number;
}

interface NLPEditingEventTextOverlay {
  text: string;
  position: NLPObjectPlacement; // отдельно от стиля
  style: CSSStyleDeclaration;
}

//prettier-ignore
export type NLPEditingEvent = NLPEditingEventBase &
                              Partial<NLPEditingEventTake> &
                              Partial<NLPEditingEventTextOverlay>;

type NLPEditingListType = Array<NLPEditingEvent>;

interface NLPEventRangeBase {
  start: number;
  stop: number;
  type: NLPEditingEventType;
  camera: number;
}

export type NLPEditingRange = NLPEventRangeBase & Partial<NLPEditingEventTextOverlay>;

export type NLPEditingRanges = Array<NLPEditingRange>;

export class NLPEditingList extends EventEmitter {
  project_id: string;
  private dt = 0;
  list: NLPEditingListType = [];

  constructor(project_id: string) {
    super();
    this.project_id = project_id;
    this.dt = Date.now();
  }

  get started(): boolean {
    return this.list.length > 0 && this.list[0].type === NLPEditingEventType.Start;
  }

  clear() {
    this.list.splice(0, this.list.length);
  }

  start(camera?: number, now?: number) {
    if (now === undefined) now = Date.now();
    this.list.push({
      type: NLPEditingEventType.Start,
      order: 0,
      dt: now,
    });
    if (camera !== undefined) {
      this.list.push({
        type: NLPEditingEventType.TakeVideo,
        order: 1,
        dt: now,
        camera: camera,
      });
    }
  }

  stop(now?: number) {
    if (now === undefined) now = Date.now();
    this.appendEvent({
      type: NLPEditingEventType.Stop,
      order: 0,
      dt: now,
    });
  }

  appendEvent(event: NLPEditingEvent): NLPEditingList {
    if (!event.dt) throw new Error('appendEvent: dt is undefined');
    const lastEl = this.list[this.list.length - 1];
    if (lastEl !== undefined) event.order = lastEl.order + 1;
    else event.order = 0;
    this.list.push(event);
    return this;
  }

  insertEvent(event: NLPEditingEvent, index: number): NLPEditingList {
    if (!event.dt) throw new Error('insertEvent: dt is undefined');
    this.list.splice(index, 0, event);
    this.updateOrder();
    return this;
  }

  deleteEvent(index: number): NLPEditingList {
    this.list.splice(index, 1);
    return this;
  }

  deleteAndReturnEvent(index: number): NLPEditingEvent | null {
    const res = this.list[index];
    if (res === undefined) return null;
    this.list.splice(index, 1);
    return res;
  }

  private updateOrder() {
    this.list.forEach((ev, idx) => (ev.order = idx));
  }

  exportForCloud() {
    return this.list.map((ev: NLPEditingEvent) => {
      const { cloudId, order, dt, type, ...action } = ev;
      return {
        cloudId: cloudId,
        order: order,
        dt: dt,
        type: type,
        action: action,
      };
    });
  }

  deleteAllEvents() {
    this.list = [];
  }

  updateIdsFromCloud(idList: Array<{ o: number; id: string }>) {
    if (this.list.length !== idList.length) throw new Error('Lists are different length');
    idList.forEach((r) => {
      const ev = this.list.find((e) => e.order === r.o);
      if (ev !== undefined) ev.cloudId = r.id;
    });
  }

  exportForPeer(camera: number): Array<Array<number>> {
    if (this.dt === 0) throw new Error('No start event in list');
    if (this.list.length === 0) throw new Error('Empty list');
    const res: Array<Array<number>> = [];

    let activeDuration: Array<number> | null = null;
    let activeMask = 0; // битмаск
    this.list.forEach((ev) => {
      if (ev.type === NLPEditingEventType.Stop) {
        if (activeDuration !== null && ev.dt !== activeDuration[0]) {
          activeDuration[1] = ev.dt;
          res.push(activeDuration);
          activeDuration = null;
        }
        return;
      }
      if (ev.type === NLPEditingEventType.TakeVideo || ev.type === NLPEditingEventType.TakeAudio) {
        if (ev.camera === camera) {
          activeMask |= 1 << ev.type;
        } else {
          activeMask &= ~(1 << ev.type);
        }
      }
      if (activeDuration === null && activeMask !== 0) {
        activeDuration = [ev.dt, 0];
      } else if (activeDuration !== null && activeMask === 0) {
        activeDuration[1] = ev.dt;
        res.push(activeDuration);
        activeDuration = null;
      }
    });

    return res;
  }

  exportAsRanges(): Array<NLPEditingRanges> {
    const result: Array<NLPEditingRanges> = [];
    const activeEventType: Array<NLPEditingRange> = [];

    this.list.forEach((ev) => {
      if (ev.type === NLPEditingEventType.Stop) {
        activeEventType.forEach((R) => {
          R.stop = ev.dt;
        });
        return;
      }

      if (ev.camera === undefined) return;

      if (result[ev.camera] === undefined) result[ev.camera] = [];

      const ae = activeEventType[ev.type];
      if (ae !== undefined) {
        // существующее событие, меняем камеру
        ae.stop = ev.dt;
        result[ae.camera].push(ae);
      }
      activeEventType[ev.type] = { camera: ev.camera, start: ev.dt, stop: 0, type: ev.type };
    });

    activeEventType.forEach((R) => {
      result[R.camera].push(R);
    });

    result.forEach((Rlist) => {
      Rlist.sort((RA, RB) => {
        return RA.start - RB.start;
      });
    });

    return result;
  }

  dump() {
    console.log(this.list);
  }
}

function getBlackEventType(type: NLPEditingEventType): NLPEditingEventType {
  switch (type) {
    case NLPEditingEventType.TakeVideo:
      return NLPEditingEventType.TakeBlack;
    case NLPEditingEventType.TakeAudio:
      return NLPEditingEventType.TakeMute;
  }
  return NLPEditingEventType.None;
}

export function flattenRangesList(list: Array<NLPEditingRanges>, type: NLPEditingEventType): NLPEditingRanges {
  const result: NLPEditingRanges = [];

  const flatList: NLPEditingRanges = list.reduceRight((A, L) => {
    L.forEach((R) => {
      if (R.type === type) A.push(R);
    });
    return A;
  }, []);

  flatList.sort((A, B) => {
    if (A.camera === B.start) return A.camera - B.camera;
    return A.start - B.start;
  });

  if (flatList.length < 2) return flatList;

  flatList.forEach((R, Ri) => {
    flatList[Ri] = Object.assign({}, R);
  });

  let cur = flatList.shift();

  const blackType = getBlackEventType(type);

  while (flatList.length > 0) {
    const next = flatList.shift();
    if (next === undefined || cur === undefined) break;

    if (next.start < cur.start) {
      if (next.camera > cur.camera) {
        if (next.stop < cur.stop) continue; // событие поглащается
        next.start = cur.stop;
      } else {
        cur.stop = next.start; // следующее событие перекрывает текущее
      }
    }

    result.push(cur);

    if (blackType !== NLPEditingEventType.None && next.start > cur.stop) {
      // следующее событие идет через "черноту"
      result.push({
        type: blackType,
        start: cur.stop,
        stop: next.start,
        camera: 0,
      });
    }

    cur = next;
  }

  if (cur !== undefined) result.push(cur);

  return result;
}
