
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    percent: {
      type: Number,
      default: 0,
    },
    percentPosition: {
      type: String,
      default: 'right',
    },
  },
  setup() {
    return {};
  },
});
