import { ref, onBeforeUnmount, Ref, watch } from 'vue';
import { useStore } from 'vuex';
import { NLPWebRTCConnector, NLPRtcConnectionSide } from '@/utils/webrtc';
import { NLPP2PEvents } from '@/utils/p2p-protocol-events';

import { NLPVideoCapture } from '@/utils/videocapture';
import { NLPVideoUpload } from '@/utils/videocapture';
import { NLPDestroyList } from '@/utils/destroyer';

import appSettings from '@/app-settings';
import appEvents from '@/utils/events';

interface ZoomParams {
  max: number;
  min: number;
  isShowZoom: boolean;
  step?: number;
  value?: number;
}

const getZoomParams = (stream: MediaStream): ZoomParams => {
  const settings: MediaTrackSettings = stream.getVideoTracks()[0].getSettings();
  const capabilities: MediaTrackCapabilities = stream.getVideoTracks()[0].getCapabilities();

  if ('zoom' in settings) {
    return {
      // eslint-disable-next-line
      // @ts-ignore
      min: capabilities.zoom.min,
      // eslint-disable-next-line
      // @ts-ignore
      max: capabilities.zoom.max,
      // eslint-disable-next-line
      // @ts-ignore
      step: capabilities.zoom.step,
      value: settings.zoom as number,
      isShowZoom: true,
    };
  }
  return { min: 0, max: 1, step: 0.1, isShowZoom: false, value: 0 };
};

const setZoom = (zoom = 1, stream: MediaStream | null) => {
  if (!stream) return;
  const settings: MediaTrackSettings = stream.getVideoTracks()[0].getSettings();
  if (!('zoom' in settings)) return;
  /* eslint-disable-next-line */
  // @ts-expect-error
  stream?.getVideoTracks().forEach((track) => track.applyConstraints({ advanced: [{ zoom }] }));
};

/**
 * FIXME !
 * Вообще то все это надо переписывать на классы, делая абстракцию в плане получения
 * объекта MediaStream с функционалом записи
 */

export function useLocalRecorder() {
  const stream_hq = ref<MediaStream | null>(null);
  const local_video = ref<HTMLVideoElement>();
  const writer = ref<NLPVideoCapture | null>(null);
  const zoomParams: Ref<ZoomParams> = ref({ min: 0, max: 5, step: 1, isShowZoom: false });

  const store = useStore();

  const createWriter = async (camera: number) => {
    const projectId = store.state.projects.currentProjectId;
    if (!projectId) throw new Error('projectId not defined');

    if (!stream_hq.value) return;
    const hq_settings = stream_hq.value.getVideoTracks()[0]?.getSettings();

    if (!hq_settings.width || !hq_settings.height) throw new Error('No video width/height defined');

    writer.value = new NLPVideoCapture({
      camera,
      projectId: projectId,
      width: hq_settings.width,
      height: hq_settings.height,
      videoBitrate: appSettings.captureVideo.bitrate,
      audioBitrate: appSettings.hqAudio.bitrate,
      fps: 30,
      videoCodec: '',
      audioCodec: '',
    });

    await writer.value.detect();
    writer.value.preStart();
    writer.value.setTimeCorrection(0);
  };

  const createRecorder = async (
    camera: number,
    videoEl: HTMLVideoElement | null = null,
    {
      videoInput,
      audioInput,
      facingMode,
    }: { videoInput: string | null; audioInput: string | null; facingMode?: string } = {
      videoInput: null,
      audioInput: null,
      facingMode: undefined,
    }
  ): Promise<MediaStream> => {
    stream_hq.value = await navigator.mediaDevices.getUserMedia({
      video: {
        deviceId: videoInput ? { exact: videoInput } : undefined,
        width: { max: 1920, ideal: appSettings.captureVideo.size.width },
        height: { max: 1080, ideal: appSettings.captureVideo.size.height },
        frameRate: appSettings.captureVideo.fps,
        facingMode: facingMode,
      },
      audio: {
        deviceId: audioInput ? { exact: audioInput } : undefined,
      },
    });

    createWriter(camera);

    zoomParams.value = { ...getZoomParams(stream_hq.value) };

    if (videoEl) videoEl.srcObject = stream_hq.value;
    else if (local_video.value) local_video.value.srcObject = stream_hq.value;

    return stream_hq.value;
  };

  watch(
    zoomParams,
    (newValue) => {
      setZoom(newValue.value, stream_hq.value);
    },
    { deep: true }
  );

  const destroyConn = () => {
    stopWriter();
    if (stream_hq.value !== null) {
      stream_hq.value.getTracks().forEach((track) => track.stop());
      stream_hq.value = null;
    }
  };

  /** 
    методы запуска остановки записи
    @startRecord - запустить запись
    @stopWriter - остановить запись
  */
  const startRecord = async () => {
    if (stream_hq.value && writer.value) {
      writer.value.start(stream_hq.value);
    }
  };

  const stopWriter = async () => {
    if (writer.value !== null) {
      writer.value.stop();
      writer.value = null;
    }
  };

  return {
    createRecorder,
    stream_hq,
    writer,
    local_video,
    stopWriter,
    startRecord,
    createWriter,
    destroyConn,
    setZoom,
    zoomParams,
  };
}

export function useWebrtc() {
  const local_video = ref<HTMLVideoElement>();
  const local_time = ref<number>(0);
  const remote_time = ref<number>(0);
  const border_color = ref<string>('yellow');
  const stream_hq = ref<MediaStream | null>(null);
  let webrtcConn: NLPWebRTCConnector | null = null;
  const writer = ref<NLPVideoCapture | null>(null);
  const isRecording = ref<boolean>(false);
  const isLoadingVideo = ref<boolean>(false);
  const isLoadedVideo = ref<boolean>(false);
  const destroyers: NLPDestroyList = new NLPDestroyList();
  const zoomParams: Ref<ZoomParams> = ref({ min: 0, max: 1, step: 0.1, isShowZoom: false });
  const store = useStore();

  const createConnection = async (
    camera: number,
    videoEl: HTMLVideoElement | null = null,
    { videoInput, audioInput }: { videoInput: string | null; audioInput: string | null } = {
      videoInput: null,
      audioInput: null,
    }
  ): Promise<NLPWebRTCConnector | null> => {
    const projectId = store.state.projects.currentProjectId;
    if (!projectId) throw new Error('projectId not defined');

    if (videoInput === 'screen' && typeof navigator.mediaDevices.getDisplayMedia === 'function') {
      stream_hq.value = await navigator.mediaDevices.getDisplayMedia({
        video: {
          width: { max: 1920, ideal: appSettings.captureVideo.size.width },
          height: { max: 1080, ideal: appSettings.captureVideo.size.height },
          frameRate: appSettings.captureVideo.fps,
        },
        audio: true,
      });
    } else {
      stream_hq.value = await navigator.mediaDevices.getUserMedia({
        video: {
          deviceId: videoInput ? { exact: videoInput } : undefined,
          width: { max: 1920, ideal: appSettings.captureVideo.size.width },
          height: { max: 1080, ideal: appSettings.captureVideo.size.height },
          frameRate: appSettings.captureVideo.fps,
        },
        audio: {
          deviceId: audioInput ? { exact: audioInput } : undefined,
        },
      });
      zoomParams.value = { ...getZoomParams(stream_hq.value) };
    }

    const hq_settings = stream_hq.value.getVideoTracks()[0]?.getSettings();

    if (!hq_settings.width || !hq_settings.height) throw new Error('No video width/height defined');

    const createWriter = async () => {
      if (writer.value) writer.value.stop();
      writer.value = new NLPVideoCapture({
        camera,
        projectId: projectId,
        width: hq_settings.width,
        height: hq_settings.height,
        videoBitrate: appSettings.captureVideo.bitrate,
        audioBitrate: appSettings.hqAudio.bitrate,
        fps: hq_settings.frameRate,
        videoCodec: '',
        audioCodec: '',
      });

      await writer.value.detect();

      await writer.value.preStart();
    };

    watch(
      zoomParams,
      (newValue) => {
        setZoom(newValue.value, stream_hq.value);
      },
      { deep: true }
    );

    await createWriter();

    if (videoEl) videoEl.srcObject = stream_hq.value;
    else if (local_video.value) local_video.value.srcObject = stream_hq.value;

    let conn: NLPWebRTCConnector | null = new NLPWebRTCConnector(NLPRtcConnectionSide.Sender, `o${camera}`, 'd');
    await conn.create(stream_hq.value);

    conn.on('pc:connectionstatechange', (state: string) => {
      if (state === 'connected') border_color.value = 'ready';
      else border_color.value = 'error';
    });

    conn.p2p.on('event:take', (cam: number) => {
      console.log('event:take', cam);
      if (cam === camera) {
        border_color.value = 'program';
        writer.value?.pushKeyFrame();
      } else {
        border_color.value = 'ready';
      }
    });

    conn.p2p.on('event:start', (cam: number) => {
      console.log('event:start', cam);
      if (stream_hq.value && writer.value && conn) {
        writer.value.setTimeCorrection(conn.localTimeDiff);
        writer.value.start(stream_hq.value);
        isRecording.value = true;
      }
    });

    conn.p2p.on('event:restart', async (cam: number) => {
      console.log('event:restart', cam);
      if (stream_hq.value) {
        await createWriter();
        isRecording.value = false;
      }
    });

    conn.p2p.on('event:delete', (cam: number) => {
      console.log('event:delete', cam);
      if (stream_hq.value && writer.value) {
        conn?.pc?.close();
        (conn as NLPWebRTCConnector).pc = null;
        conn = null;
        stream_hq.value?.getTracks().forEach((track) => {
          track.stop();
        });
      }
    });

    conn.p2p.on('event:uploadRanges', async (camera: number, ranges: Array<Array<number>>, totalLength: number) => {
      isLoadingVideo.value = true;
      if (writer.value !== null) {
        writer.value.stop();
      }
      if (stream_hq.value !== null) {
        stream_hq.value.getTracks().forEach((track) => {
          track.stop();
        });
      }
      const U = new NLPVideoUpload(store.state.projects.currentProjectId);
      await U.open();

      await U.uploadAll(camera);

      destroyers.destroyTimer(
        setTimeout((conn: NLPWebRTCConnector) => {
          isLoadedVideo.value = true;
        }, 500),
        'upload-video'
      );
    });

    conn.p2p.on('event:stop', (cam: number) => {
      if (writer.value !== null) {
        writer.value.pushKeyFrame();
        window.setTimeout((W: NLPVideoCapture) => W.stop(), 1000, writer.value);
        writer.value = null;
      }
      isRecording.value = false;
    });

    if (!conn) return conn;

    conn.once('timesync', () => {
      if (writer.value !== null && conn != null) {
        writer.value.setTimeCorrection(conn.localTimeDiff);
      }
    });

    appEvents.on('app:uploadStart', (camera, packetCount) => {
      conn?.p2p.emitRemote(NLPP2PEvents.uploadProgress(camera, 0, packetCount, false, false));
    });

    appEvents.on('app:uploadProgress', (camera, packetCount, cur, total) => {
      conn?.p2p.emitRemote(NLPP2PEvents.uploadProgress(camera, total.packets, packetCount, false, cur.error));
    });

    appEvents.on('app:uploadDone', (camera, packetCount, total, error) => {
      conn?.p2p.emitRemote(NLPP2PEvents.uploadProgress(camera, total.packets, packetCount, true, error));
    });

    webrtcConn = conn;
    return conn;
  };

  onBeforeUnmount(async () => {
    destroyers.destroyAll();
  });

  const stop = async () => {
    if (writer.value !== null) writer.value.stop();
    if (webrtcConn !== null) webrtcConn.destroy();
    if (stream_hq.value !== null) {
      stream_hq.value.getTracks().forEach((track) => {
        track.stop();
      });
    }
  };

  return {
    createConnection,
    stream_hq,
    writer,
    local_video,
    local_time,
    remote_time,
    border_color,
    isRecording,
    stop,
    isLoadingVideo,
    isLoadedVideo,
    zoomParams,
  };
}
