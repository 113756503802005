import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-451127ea"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "home" }
const _hoisted_2 = { class: "home__header" }
const _hoisted_3 = { class: "home__burger-menu" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_ButtonUser = _resolveComponent("ButtonUser")!
  const _component_PwaButton = _resolveComponent("PwaButton")!
  const _component_AppVersion = _resolveComponent("AppVersion")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", null, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_Icon, { icon: "burger" })
        ])
      ]),
      _createVNode(_component_ButtonUser, {
        class: "home__button-director",
        text: "Я — автор",
        onClick: _ctx.routerPushProjects
      }, null, 8, ["onClick"]),
      _createVNode(_component_ButtonUser, {
        type: "secondary",
        class: "home__button-operator",
        onClick: _ctx.openLoginOperator,
        text: "Я — соавтор"
      }, null, 8, ["onClick"])
    ]),
    _createVNode(_component_PwaButton, { class: "home__pwa" }),
    _createVNode(_component_AppVersion)
  ]))
}