
import { computed, defineAsyncComponent, defineComponent } from 'vue';

export default defineComponent({
  name: 'IconMix',
  props: {
    icon: String,
    disabled: { type: Boolean, default: false },
    color: {
      type: String,
      default: '',
      validator: function (value: string) {
        return ['white', 'black', 'current'].includes(value);
      },
    },
  },
  setup(props) {
    const svg = computed(() => defineAsyncComponent(() => import(`./assets/${props.icon}.svg?component`)));
    return { svg };
  },
});
