interface ProjectRenderInfo {
  dt: number;
  duration: number;
  error: boolean;
  finished: boolean;
  progress: number;
  size: number;
}

export interface Project {
  dt: string;
  id: string;
  image: string;
  name: string;
  duration: number;
  size: string;
  render: ProjectRenderInfo;
}

export interface StateProject {
  currentProjectAuth: string | null;
  currentProjectId: string | null;
  projects: Project[];
}

const state: StateProject = {
  currentProjectAuth: null,
  currentProjectId: null,
  projects: [],
};

export type State = typeof state;
export default state;
