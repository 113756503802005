
import { computed, defineComponent, onBeforeUnmount, onMounted, ref, toRefs } from 'vue';
import Icon from '@/components/common/icon/Icon.vue';
import { NLPEditingEvent } from '@/utils/editinglist';
import { useStore } from 'vuex';

export default defineComponent({
  name: 'VideoPlayer',
  components: { Icon },
  props: {
    videos: {
      type: Array<string>,
      default: () => [],
    },
    list: {
      type: Array<NLPEditingEvent>,
      default: () => [],
    },
    type: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const { list } = toRefs(props);
    const currentPlayingVideo = ref<number>(0);
    const currentPlayingIndexList = ref<number>(1);

    const store = useStore();

    const isPlayingVideo = ref<boolean>(false);

    const durationVideo = computed(() => {
      const ms = list.value[list.value.length - 1]?.dt - list.value[0]?.dt;
      const minutes = Math.floor(ms / 1000 / 60);
      const seconds = Math.floor(ms / 1000);
      store.dispatch('video/getDuration', ms);
      return {
        m: minutes < 10 ? `0${minutes}` : minutes,
        s: seconds < 10 ? `0${seconds}` : seconds,
      };
    });

    const setIsPlayingVideo = () => {
      if (isPlayingVideo.value) {
        videos.value.forEach((element: HTMLVideoElement) => {
          element?.pause();
        });
        if (timeoutForSwitch.value) {
          clearTimeout(timeoutForSwitch.value);
        }
      } else {
        switchVideo(currentPlayingIndexList.value);
        videos.value.forEach((element: HTMLVideoElement) => {
          element?.play();
        });
      }
      isPlayingVideo.value = !isPlayingVideo.value;
    };

    const videos = ref<Array<HTMLVideoElement>>([]);

    const setVideo = (el: any) => {
      if (el) {
        videos.value.push(el);
      }
    };

    const currentTime = ref();

    const getCurrentTimeVideo = (ev: any) => {
      store.dispatch('video/getCurrentTime', ev.target.currentTime);
      const minutes = Math.floor(ev.target.currentTime / 60);
      const seconds = Math.floor(ev.target.currentTime);
      currentTime.value = {
        s: seconds < 10 ? `0${seconds}` : seconds,
        m: minutes < 10 ? `0${minutes}` : minutes,
      };
    };

    const timeoutForSwitch = ref<number | null>(null);

    const restartVideo = () => {
      videos.value.forEach((element: HTMLVideoElement) => {
        element.currentTime = 0;
        element?.pause();
      });
      if (timeoutForSwitch.value) {
        clearTimeout(timeoutForSwitch.value);
      }

      currentPlayingVideo.value = 0;
      currentPlayingIndexList.value = 1;
      isPlayingVideo.value = false;
    };

    const switchVideo = (listIndex: number) => {
      if (list.value[listIndex].camera === undefined) {
        restartVideo();
        return;
      }
      currentPlayingVideo.value = list.value[listIndex].camera as number;
      videos.value[currentPlayingVideo.value - 1].play();

      currentPlayingIndexList.value = listIndex;
      const nextVideoIndex = currentPlayingIndexList.value + 1;

      const timeInterval =
        list.value[currentPlayingIndexList.value + 1].dt -
        list.value[0].dt -
        +(videos.value[currentPlayingVideo.value - 1]?.currentTime || 0).toFixed(3) * 1000;
      timeoutForSwitch.value = setTimeout(switchVideo, timeInterval, nextVideoIndex);
    };

    onMounted(() => {
      videos.value.forEach((element: HTMLVideoElement, index) => {
        element.currentTime = 0;
        if (index === 0) {
          element.addEventListener('timeupdate', getCurrentTimeVideo);
        }
      });
    });

    onBeforeUnmount(() => {
      videos.value[0].removeEventListener('timeupdate', getCurrentTimeVideo);
    });

    return {
      currentPlayingVideo,
      setIsPlayingVideo,
      setVideo,
      durationVideo,
      currentTime,
      restartVideo,
      isPlayingVideo,
    };
  },
});
