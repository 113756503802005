
import { defineComponent, onMounted, ref } from 'vue';

import { api } from '@/utils/api';
import { NLPWebRTCConnector, NLPRtcConnectionSide } from '@/utils/webrtc';
import { NLPP2PEvents } from '@/utils/p2p-protocol-events';
import { NLPMediaRecorder } from '@/utils/mediarecorder';
import vueQr from 'vue-qr/src/packages/vue-qr.vue';
import store from '@/store';
import { useStore } from 'vuex';

export default defineComponent({
  name: 'TestWebRTC',
  components: {
    vueQr,
  },
  setup() {
    const store = useStore();

    const input_video = ref<Array<HTMLVideoElement>>();
    const pgm_video = ref<Array<HTMLVideoElement>>();
    const result_video = ref<Array<HTMLVideoElement>>();
    const pgm_channel = ref<number>(0);
    const local_time = ref<number>(0);
    const remote_time = ref<Array<number>>([]);
    const local_remote_time = ref<Array<number>>([]);
    const remote_resolution = ref<Array<string>>([]);
    const dvrs: Array<NLPMediaRecorder> = [];
    const conns: Array<NLPWebRTCConnector> = [];
    const qr_text = ref<Array<string>>([]);

    async function connectVideo(cam: number, conn: NLPWebRTCConnector) {
      if (input_video.value === undefined) return;
      if (pgm_video.value === undefined) return;
      const video_chn = input_video.value[cam - 1];
      const st = await conn.getReceiveStream();
      console.log('rcv stream', cam, st);
      st.getTracks().forEach((track) => {
        console.log('rcv track', cam, track.getSettings());
      });
      video_chn.srcObject = st;
      video_chn.addEventListener(
        'loadedmetadata',
        () => {
          remote_resolution.value[cam] = `${video_chn.videoWidth}x${video_chn.videoHeight}`;
        },
        {
          once: true,
        }
      );
      const video_pgm = pgm_video.value[cam - 1];
      video_pgm.srcObject = await conn.getReceiveStream();
    }

    window.setInterval(() => {
      local_time.value = Date.now();
      api().reportDirectorIsOnline();
    }, 1000);

    onMounted(async () => {
      console.log('test director');

      for (let cam = 1; cam < 5; ++cam) {
        const conn = new NLPWebRTCConnector(NLPRtcConnectionSide.Receiver, 'd', `o${cam}`);
        await conn.create();
        conns[cam] = conn;
        console.log('cam', cam, conn);

        conn.on('open', connectVideo.bind(this, cam, conn));

        window.setInterval(
          (conn: NLPWebRTCConnector) => {
            remote_time.value[cam] = conn.remoteTime;
            local_remote_time.value[cam] = conn.localTimeDiff;
          },
          1000,
          conn
        );
      }

      /*
      const stream1=await navigator.mediaDevices.getUserMedia({
        video: {
          width: 1280,
          height: 720,
        },
        audio: false,
      });

      const stream2=await navigator.mediaDevices.getUserMedia({
        video: {
          width: 128,
          height: 72,
        },
        audio: false,
      });

      if (video3.value) video3.value.srcObject=stream1;
      if (video1.value) video1.value.srcObject=stream2;

      const conn2=new NLPWebRTCConnector(NLPRtcConnectionSide.Sender,'o1','d');
      await conn2.create(stream2);
      console.log(conn2);
      if (video2.value) video2.value.srcObject=await conn1.getReceiveStream();
*/
    });

    function cmd_take(cam: number) {
      console.log('take', cam);
      pgm_channel.value = cam;
      conns.forEach((conn) => {
        if (conn && conn.connected) {
          conn.p2p.emitRemote(NLPP2PEvents.take(cam));
        }
      });
    }

    async function cmd_start() {
      for (let cam = 1; cam < 5; ++cam) {
        const conn = conns[cam];
        if (conn === undefined || !conn.connected) continue;
        console.log('start cam', cam);
        const dvr = new NLPMediaRecorder('cam' + cam);
        dvr.start(await conn.getReceiveStream());
        dvrs[cam] = dvr;
        conn.p2p.emitRemote(NLPP2PEvents.start(cam, Date.now()));
      }
      console.log(dvrs);
    }

    function cmd_stop() {
      console.log('stop');
      const vids = dvrs.map((dvr) => {
        return dvr.stop();
      });
      vids.forEach((vid, idx) => {
        if (vid == null || !Array.isArray(result_video.value)) return;
        const url = window.URL.createObjectURL(vid);
        result_video.value[idx - 1].src = url;
      });
    }

    async function cmd_make_qr(cam: number) {
      //const projectId = process.env.VUE_APP_PROJECTAUTH.split(':')[0];
      const projectId = store.state.projects.currentProjectAuth || process.env.VUE_APP_PROJECTAUTH.split(':')[0];
      console.log(projectId);
      const text = await api().requestQrCode(projectId, cam);
      console.log(text);
      if (text !== '') qr_text.value[cam] = `${location.origin}/qr/${text}`;
    }

    return {
      input_video,
      pgm_video,
      pgm_channel,
      local_time,
      remote_time,
      local_remote_time,
      remote_resolution,
      result_video,
      qr_text,
      cmd_take,
      cmd_start,
      cmd_stop,
      cmd_make_qr,
    };
  },
});
