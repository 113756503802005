
import ButtonUser from '@/components/common/buttons/Button.vue';
import Icon from '@/components/common/icon/Icon.vue';
import VideoPlayer from '@/components/video/VideoPlayer.vue';
import VideoTimeline from '@/components/videoEditor/VideoTimeline.vue';
import { computed, defineComponent, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { api } from '@/utils/api';
import axios from 'axios';

export default defineComponent({
  name: 'VideoEditor',
  components: { Icon, VideoPlayer, VideoTimeline, ButtonUser },
  setup() {
    const router = useRouter();

    const closePage = () => {
      router.push('/producer-projects');
    };

    const currentStep = ref<string>('editing');

    const setCurrentStep = (value: string) => {
      currentStep.value = value;
      if (value === 'download') {
        renderVideo();
      }
    };

    const store = useStore();

    const list = computed(() => {
      return store.state.video.list;
    });

    const videos = computed(() => {
      return store.state.video.videos;
    });

    const isRenderVideo = ref(false);
    const percentRender = ref<number>(0);
    const isDownloadVideo = ref(false);
    const percentDownLoad = ref<number>(0);

    const videoDownLoad: any = ref(null);

    const renderVideo = async () => {
      isRenderVideo.value = true;
      const render_res = await api().renderVideo(
        store.state.projects.currentProjectId,
        (current: number, duration: number) => {
          percentRender.value = current;
        }
      );
      isRenderVideo.value = false;

      if (render_res === false) {
        store.dispatch('modals/getIsShowModalError', { value: true });
        return;
      }
    };

    const uploadVideo = async () => {
      if (isDownloadVideo.value) return;
      isDownloadVideo.value = true;
      videoDownLoad.value = await api().downloadVideo(store.state.projects.currentProjectId, (ev: any) => {
        percentDownLoad.value = +ev.progress.toFixed(1) * 100;
      });

      if (videoDownLoad.value === null) {
        store.dispatch('modals/getIsShowModalError', { value: true });
        return;
      }
    };

    const download = async () => {
      await uploadVideo();
      const url = URL.createObjectURL(videoDownLoad.value);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'video.mp4');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    };

    const shareVideo = async () => {
      await uploadVideo();
      if (typeof navigator.canShare === 'function') {
        const shareData = {
          files: [new File([videoDownLoad.value], 'video.mp4', { type: videoDownLoad.value.type })],
          title: 'Project video',
          text: 'Project video text',
        };
        if (navigator.canShare(shareData)) {
          await navigator.share(shareData);
        }
      }
    };

    const isUploadedWasd = ref(false);

    const uploadWasd = async () => {
      try {
        await api().uploadWasd(store.state.projects.currentProjectId);
        store.dispatch('modals/getIsShowModalSuccess', true);
        isUploadedWasd.value = true;
      } catch (error) {
        store.dispatch('modals/getIsShowModalError', { value: true });
        return;
      }
    };

    return {
      closePage,
      setCurrentStep,
      videos,
      list,
      currentStep,
      renderVideo,
      percentRender,
      percentDownLoad,
      shareVideo,
      download,
      isDownloadVideo,
      isRenderVideo,
      uploadWasd,
      isUploadedWasd,
    };
  },
});
