import { NLPEditingEvent } from '@/utils/editinglist';

export interface StateProject {
  videos: string[];
  list: NLPEditingEvent[];
  currentTime: number;
  duration: number;
}

const state: StateProject = {
  videos: [
    // 'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerMeltdowns.mp4',
    // 'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerEscapes.mp4',
  ],
  list: [
    // { type: 1, order: 0, dt: 1686377973309, cloudId: '19cab615-37c7-40e1-a566-39f14663c286' },
    // { type: 3, order: 1, dt: 1686377973309, camera: 1, cloudId: 'db0fef80-40f7-4309-be54-31a9218c5a9f' },
    // { dt: 1686377979759, order: 2, type: 3, camera: 2, cloudId: '7d9599bf-e3e9-466a-92f9-77a2cc42a33f' },
    // { dt: 1686377986342, order: 3, type: 3, camera: 1, cloudId: '7679c447-bbd6-4076-972b-caaa0363674a' },
    // { dt: 1686377992392, order: 4, type: 3, camera: 2, cloudId: '54660ab1-6166-4bc8-ba06-73b7ccd66931' },
    // { type: 2, order: 5, dt: 1686377997977, cloudId: '86d19a20-55a5-4d3d-9bb1-64d20f1a86ca' },
  ],
  currentTime: 0,
  duration: 0,
};

export type State = typeof state;
export default state;
