
import { computed, defineComponent } from 'vue';
import Modal from '@/components/common/Modal.vue';
import ButtonUser from '@/components/common/buttons/Button.vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import Icon from '../common/icon/Icon.vue';

export default defineComponent({
  components: { Modal, ButtonUser, Icon },
  name: 'ModalError',
  setup() {
    const store = useStore();
    const router = useRouter();

    const isShowModal = computed(() => {
      return store.state.modals.isShowModalError || store.state.modals.isShowModalSuccess;
    });

    const isShowModalError = computed(() => {
      return store.state.modals.isShowModalError;
    });

    const isShowModalSuccess = computed(() => {
      return store.state.modals.isShowModalSuccess;
    });

    const errorText = computed(() => {
      return store.state.modals.errorText || 'Произошла ошибка';
    });

    const closeModal = () => {
      store.dispatch('modals/getIsShowModalError', { value: false });
      store.dispatch('modals/getIsShowModalSuccess', false);
    };

    const goToMainMenu = () => {
      closeModal();
      router.push({ path: '/' });
    };

    return {
      isShowModal,
      closeModal,
      isShowModalError,
      isShowModalSuccess,
      goToMainMenu,
      errorText,
    };
  },
});
