import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vShow as _vShow, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6d00f60d"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "video-player" }
const _hoisted_2 = { class: "video-player__videos" }
const _hoisted_3 = ["src"]
const _hoisted_4 = {
  key: 0,
  class: "video-player__controls"
}
const _hoisted_5 = { class: "video-player__controls-time" }
const _hoisted_6 = { class: "video-player__controls-btn-repeat" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.videos, (previewUrl, index) => {
        return _withDirectives((_openBlock(), _createElementBlock("video", {
          key: 'replay' + index,
          ref_for: true,
          ref: _ctx.setVideo,
          src: previewUrl,
          muted: "",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.setIsPlayingVideo && _ctx.setIsPlayingVideo(...args)))
        }, null, 8, _hoisted_3)), [
          [_vShow, index === _ctx.currentPlayingVideo - 1]
        ])
      }), 128)),
      _createElementVNode("div", {
        class: "video-player__controls-video",
        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.setIsPlayingVideo && _ctx.setIsPlayingVideo(...args)))
      }, [
        (!_ctx.isPlayingVideo && _ctx.type === 'viewOnly')
          ? (_openBlock(), _createBlock(_component_Icon, {
              key: 0,
              icon: "play-small",
              color: "white"
            }))
          : _createCommentVNode("", true)
      ])
    ]),
    (_ctx.type !== 'viewOnly')
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.currentTime?.m || '00') + "." + _toDisplayString(_ctx.currentTime?.s || '00') + "/" + _toDisplayString(_ctx.durationVideo.m) + "." + _toDisplayString(_ctx.durationVideo.s), 1),
          _createElementVNode("div", {
            class: "video-player__controls-btn-play",
            onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.setIsPlayingVideo && _ctx.setIsPlayingVideo(...args)))
          }, [
            (!_ctx.isPlayingVideo)
              ? (_openBlock(), _createBlock(_component_Icon, {
                  key: 0,
                  icon: "play-small",
                  color: "white"
                }))
              : (_openBlock(), _createBlock(_component_Icon, {
                  key: 1,
                  icon: "stop",
                  color: "white"
                }))
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_Icon, {
              icon: "replay",
              color: "white",
              onClick: _ctx.restartVideo
            }, null, 8, ["onClick"])
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}