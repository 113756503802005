import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0ea6dd98"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "modal"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.showModal)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", {
          class: _normalizeClass(`modal__content modal__content_${_ctx.position}`),
          ref: "modalContent"
        }, [
          _renderSlot(_ctx.$slots, "default", {}, undefined, true)
        ], 2)
      ]))
    : _createCommentVNode("", true)
}