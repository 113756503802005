import StoreUtils from '@/utils/store';

import appSettings from '@/app-settings';
import { api } from '@/utils/api';
import appEvents from '@/utils/events';

interface NLPCodecInfo {
  name: string;
  bitrate: number;
  dt0: number;
  width?: number;
  height?: number;
  fps?: number;
  audioChannels?: number;
  audioSampleRate?: number;
}

export interface NLPTimeRange {
  start: number;
  stop: number;
}

export function nlpTimeRange(start: number, stop: number): NLPTimeRange {
  return {
    start: start,
    stop: stop,
  };
}

enum MediaTrackType {
  None,
  Video,
  VideoLQ,
  Audio,
  Metadata,
}

interface NLPCodecState {
  counter: number;
  trackId: number;
  type: MediaTrackType;
  encoder?: VideoEncoder | AudioEncoder;
  trackProcessor?: MediaStreamTrackProcessor<AudioData | VideoFrame>;
  stream?: WritableStream;
  keyInt: number;
  ts0: number;
  forceKeyFrame: boolean;
  extraData: Uint8Array;
  config: object;
  lastTimestamp: number;
}

interface NLPCaptureWorkerOptions {
  projectId: string;
  camera: number;
  width?: number;
  height?: number;
  fps?: number;
  videoCodec: string;
  videoBitrate: number;
  cbr?: boolean;
  audioCodec: string;
  audioBitrate: number;
  preferSoftware?: boolean;
}

export interface NLPVideoCaptureDatabaseRecord {
  camera: number;
  track: number;
  dt: number;
  pts: number;
  is_key: boolean;
  duration: number;
  type: MediaTrackType;
  data: ArrayBuffer;
  upload_ok: boolean;
  projectId?: string;
}

const MediaRecorderDatabaseVersion = 2;

class NLPVideoCaptureDatabase {
  projectId: string;
  private db: IDBDatabase | null = null;
  private wal: Array<NLPVideoCaptureDatabaseRecord> = [];
  private walTimer = 0;

  constructor(projectId: string) {
    this.projectId = projectId;
    this.syncWal = this.syncWal.bind(this);
  }

  deleteDatabase() {
    return new Promise((resolve, reject) => {
      const delReq = window.indexedDB.deleteDatabase('nlp-savedata');
      delReq.addEventListener('error', reject);
      delReq.addEventListener('success', resolve);
    });
  }

  open(): Promise<NLPVideoCaptureDatabase> {
    return new Promise((resolve, reject) => {
      const openReq = window.indexedDB.open('nlp-savedata', MediaRecorderDatabaseVersion);
      openReq.addEventListener('error', reject);
      openReq.addEventListener('upgradeneeded', () => {
        const db = openReq.result;
        db.addEventListener('error', reject);
        try {
          db.deleteObjectStore('frames');
        } catch (err) {
          false;
        }
        db.createObjectStore('frames', { keyPath: ['camera', 'dt', 'track'] });
      });
      openReq.addEventListener('success', () => {
        this.db = openReq.result;
        this.wal.splice(0, this.wal.length);
        resolve(this);
      });
    });
  }

  private cancelWal() {
    if (this.walTimer !== 0) {
      window.clearTimeout(this.walTimer);
      this.walTimer = 0;
    }
    this.wal.splice(0, this.wal.length);
  }

  private async syncWal(fromTimer = false) {
    if (fromTimer === false) {
      window.clearTimeout(this.walTimer);
      this.walTimer = 0;
    }
    if (this.wal.length === 0) return;

    if (fromTimer === true) {
      this.walTimer = window.setTimeout(this.syncWal, 1000, true);
    }

    const store = this.getStore('frames');
    if (!store) throw new Error('Can\'t get database store'); // eslint-disable-line

    const saveWal = this.wal.splice(0, this.wal.length);

    for (let i = 0; i < saveWal.length; ++i) {
      const data = saveWal[i];
      try {
        await this.waitForOp(store.add(data));
      } catch (err) {
        console.log('error when save wal', err);
        console.log('record', data);
        //eslint-disable-next-line
        //debugger;
      }
    }
  }

  private createKey(camera: number, dt: number, track: number): Array<number> {
    return [camera, dt, track];
  }

  private getStore(table: string): IDBObjectStore {
    if (this.db === null) throw new Error('Database not opened');
    const T: IDBTransaction = this.db.transaction([table], 'readwrite');
    return T.objectStore(table);
  }

  private waitForOp(request: IDBRequest): Promise<Event> {
    return new Promise((resolve, reject) => {
      request.addEventListener('error', reject, { once: true });
      request.addEventListener('success', resolve, { once: true });
    });
  }

  async clear() {
    if (this.db === null) throw new Error('Database not opened');
    this.cancelWal();
    const store = this.getStore('frames');
    if (!store) throw new Error('Can\'t get database store'); // eslint-disable-line
    await this.waitForOp(store.clear());
  }

  async deleteCamera(camera: number) {
    if (this.db === null) throw new Error('Database not opened');
    this.cancelWal();
    const store = this.getStore('frames');
    if (!store) throw new Error('Can\'t get database store'); // eslint-disable-line
    //prettier-ignore
    await this.waitForOp(
      store.delete(
        IDBKeyRange.bound(
          this.createKey(camera, Number.NEGATIVE_INFINITY, 0),
          this.createKey(camera, Number.POSITIVE_INFINITY, 65535)
        )
      )
    );
  }

  addRecord(data: NLPVideoCaptureDatabaseRecord, sync: boolean) {
    if (this.db === null) throw new Error('Database not opened');
    if (sync === false) {
      this.wal.push(data);
      if (this.walTimer === 0) {
        this.walTimer = window.setTimeout(this.syncWal, 1000, true);
      }
      return;
    }
    const store = this.getStore('frames');
    if (!store) throw new Error('Can\'t get database store'); // eslint-disable-line

    return this.waitForOp(store.add(data));
  }

  async getProjectHeader() {
    if (this.db === null) throw new Error('Database not opened');
    await this.syncWal();
    const store = this.getStore('frames');
    if (!store) throw new Error('Can\'t get database store'); // eslint-disable-line

    const op = store.getAll(IDBKeyRange.bound(this.createKey(0, -2, 0), this.createKey(100, -2, 65535), true, true), 6);
    await this.waitForOp(op);
    return op.result;
  }

  private filterCamera(camera: number, list: Array<NLPVideoCaptureDatabaseRecord>) {
    return list.filter((rec) => {
      return rec.camera === camera;
    });
  }

  async *walkPacketsRaw(
    camera: number,
    dt: number,
    maxRecords: number
  ): AsyncGenerator<Array<NLPVideoCaptureDatabaseRecord>> {
    await this.syncWal();

    const store = this.getStore('frames');
    if (!store) throw new Error('Can\'t get database store'); // eslint-disable-line

    const extraData = store.getAll(IDBKeyRange.bound(this.createKey(camera, -2, 0), this.createKey(camera, -1, 65535)));
    await this.waitForOp(extraData);

    const filteredHeader = this.filterCamera(camera, extraData.result);
    if (filteredHeader.length === 0) return;

    yield filteredHeader;

    let lastDt = 0;
    if (dt !== undefined) lastDt = dt;

    let lastRecord: NLPVideoCaptureDatabaseRecord | null = null;
    for (;;) {
      const store = this.getStore('frames');
      if (!store) throw new Error('Can\'t get database store'); // eslint-disable-line

      const records = store.getAll(
        IDBKeyRange.bound(this.createKey(camera, lastDt, 0), this.createKey(camera, Number.POSITIVE_INFINITY, 65535)),
        maxRecords
      );
      await this.waitForOp(records);
      if (records.error !== null) throw records.error;

      if (!Array.isArray(records.result)) break;

      const resultLength = records.result.length;
      if (resultLength === 0) break;

      if (lastRecord !== null) {
        let idx = 0;
        while (records.result[idx].dt === lastRecord.dt && idx < records.result.length) {
          const xrec = records.result[idx];
          if (xrec.track === lastRecord.track && xrec.pts === lastRecord.pts && xrec.is_key === lastRecord.is_key) {
            records.result.splice(0, idx + 1);
            break;
          }
          ++idx;
        }
      }
      lastRecord = records.result[records.result.length - 1];
      if (!lastRecord) break;
      lastDt = lastRecord.dt;

      const filtered = this.filterCamera(camera, records.result);

      if (filtered.length > 0) yield filtered;
      if (resultLength < maxRecords) break;
    }
  }

  async *walkPacketsRanges(
    camera: number,
    ranges: Array<NLPTimeRange>
  ): AsyncGenerator<Array<NLPVideoCaptureDatabaseRecord>> {
    await this.syncWal();

    let store = this.getStore('frames');
    if (!store) throw new Error('Can\'t get database store'); // eslint-disable-line

    const extraData = store.getAll(IDBKeyRange.bound(this.createKey(camera, -2, 0), this.createKey(camera, -1, 65535)));
    await this.waitForOp(extraData);

    const filteredHeader = this.filterCamera(camera, extraData.result);
    if (filteredHeader.length === 0) return;

    yield filteredHeader;

    //const fromKeyFrame: Array<NLPVideoCaptureDatabaseRecord> = [];

    ranges.sort((A, B) => {
      return A.start - B.start;
    });

    const interval = 2000;

    const optRanges = ranges.reduce((res, R) => {
      R.start -= interval / 2;
      R.stop += interval / 2;
      if (R.stop < R.start + interval) R.stop = R.start + interval;
      if (res.length === 0) {
        res.push(R);
        return res;
      }
      const Rprev = res[res.length - 1];
      //prettier-ignore
      if (R.start < Rprev.stop || (Rprev.stop + interval) > R.start) {
        Rprev.stop = R.stop;
      } else {
        res.push(R);
      }
      return res;
    }, new Array<NLPTimeRange>());

    if (optRanges.length === 0) return null;

    optRanges.sort((A, B) => {
      return A.start - B.start;
    });

    store = this.getStore('frames');
    if (!store) throw new Error('Can\'t get database store'); // eslint-disable-line

    let rangeIdx = 0;
    let sendingPackets = false;
    let keyBuffer: Array<NLPVideoCaptureDatabaseRecord> = [];
    let cursor = store.openCursor(
      IDBKeyRange.bound(
        this.createKey(camera, optRanges[rangeIdx].start - 15000, 0),
        this.createKey(camera, Number.POSITIVE_INFINITY, 65535)
      )
    );
    let rangeData: Array<NLPVideoCaptureDatabaseRecord> = [];
    for (;;) {
      const data = await this.waitForOp(cursor);
      if (!data) return null;
      const el = cursor.result;
      if (el) {
        const packet: NLPVideoCaptureDatabaseRecord = el.value;
        if (packet.camera !== camera) {
          el.continue();
          continue;
        }

        const inRange = packet.dt >= ranges[rangeIdx].start && packet.dt <= ranges[rangeIdx].stop;

        if (sendingPackets) {
          if (inRange === false) {
            sendingPackets = false;
            ++rangeIdx;
            yield rangeData;
            rangeData = [];
            if (rangeIdx === optRanges.length) return null;

            store = this.getStore('frames');
            if (!store) throw new Error('Can\'t get database store'); // eslint-disable-line

            cursor = store.openCursor(
              IDBKeyRange.bound(
                this.createKey(camera, optRanges[rangeIdx].start - 15000, 0),
                this.createKey(camera, Number.POSITIVE_INFINITY, 65535)
              )
            );
            continue;
          } else {
            rangeData.push(packet);
          }
        } else {
          if (inRange === true) {
            rangeData = keyBuffer;
            keyBuffer = [];
            rangeData.push(packet);
            sendingPackets = true;
          } else {
            if (packet.is_key === true) keyBuffer.splice(0, keyBuffer.length);
            keyBuffer.push(packet);
          }
        }

        el.continue();
      } else break;
    }
    yield rangeData;
    return null;
  }

  async countAll(camera: number): Promise<number> {
    if (this.db === null) throw new Error('Database not opened');

    await this.syncWal();

    const store = this.getStore('frames');
    if (!store) throw new Error('Can\'t get database store'); // eslint-disable-line

    //prettier-ignore
    const op = store.count(
      IDBKeyRange.bound(
        this.createKey(camera, Number.NEGATIVE_INFINITY, 0),
        this.createKey(camera, Number.POSITIVE_INFINITY, 65535)
      )
    );
    await this.waitForOp(op);
    return op.result;
  }

  async countRange(camera: number, start: number, stop: number): Promise<number> {
    if (this.db === null) throw new Error('Database not opened');

    await this.syncWal();

    const store = this.getStore('frames');
    if (!store) throw new Error('Can\'t get database store'); // eslint-disable-line

    //prettier-ignore
    const op = store.count(
      IDBKeyRange.bound(
        this.createKey(camera, start, 0),
        this.createKey(camera, stop, 65535), 
        true, true
      )
    );
    await this.waitForOp(op);
    return op.result;
  }
}

export class NLPCaptureWorker {
  demuxer = null;
  options: NLPCaptureWorkerOptions;
  type = 'unknown';
  db: NLPVideoCaptureDatabase;
  timeCorrection = 0;

  protected startTime = 0;
  protected captureStream: MediaStream | null = null;
  protected extraData: Array<Uint8Array> = [];
  protected camera = 0;
  protected refStreams: Set<MediaStream> = new Set();

  constructor(options: NLPCaptureWorkerOptions) {
    this.options = options;
    this.db = new NLPVideoCaptureDatabase(options.projectId);
    this.camera = options.camera;
  }

  demuxerError(error: Error) {
    console.log('Demuxer error', error);
  }

  // eslint-disable-next-line
  encoderError(type: string, state: any, error: Error) {
    console.log(`Encoder error ${type}`, error);
    console.log('Encoder state', state);
    api().sendCodecDebug(`error${this.camera}-${state.trackId}`, { error: error, state: state });
  }

  async preStart() {
    await this.db.open();
    //await this.db.clear();
    await this.db.deleteCamera(this.camera);
  }

  async start(captureStream: MediaStream, masterDt0?: number) {
    if (masterDt0 !== undefined) {
      this.startTime = masterDt0;
    } else {
      this.startTime = Date.now() - this.timeCorrection;
    }
    this.captureStream = captureStream;
    this.refStreams.add(captureStream);
    api().sendDebug(`writerstart${this.camera}`, { startDt: this.startTime, corr: this.timeCorrection });
  }

  stop() {
    if (this.captureStream === null) return;
    this.refStreams.forEach((st) => {
      [...st.getTracks()].forEach((t) => t.stop());
    });
    this.refStreams.clear();
    this.captureStream = null;
  }

  getWallTime(dts: number, timescale: number) {
    if (timescale === 1000) return dts + this.startTime;
    const ts = 1000 / timescale;
    return Math.round(ts * dts) + this.startTime;
  }

  async processData() {
    throw new Error('Abstract "processData" method called');
  }

  pushKeyFrame() {
    throw new Error('Abstract "pushkeyFrame" method called');
  }
}

class NLPCaptureWorker_WebCodecs extends NLPCaptureWorker {
  static detectVideoCodecs = [
    'avc1.4d002a',
    'avc1.420028',
    'avc1.420029',
    'avc1.42002a',
    'avc1.420032',
    'avc1.424028',
    'avc1.42001e',
    'av01.0.00M.08',
    'av01.0.10M.08',
    'av01.0.00H.08',
    'av01.0.10H.08',
    'hev1.1.6.L93.B0',
    'hev1.2.4.L120.B0',
    'vp8',
    'vp09.00.10.08',
  ];
  static detectAudioCodecs = ['mp4a.40.2', 'opus', 'mp4a.67'];
  static detectAccelerations: Array<HardwarePreference> = ['prefer-hardware', 'prefer-software'];

  static async detectCodecs(options: NLPCaptureWorkerOptions) {
    // eslint-disable-next-line
    const supportedCodecs: { video: string; audio: string; videoConfig: any; audioConfig: any } = {
      video: '',
      audio: '',
      videoConfig: null,
      audioConfig: null,
    };
    if (!options.width || !options.height) throw new Error('width or height are not defined');
    for (const acceleration of this.detectAccelerations) {
      for (const codec of this.detectVideoCodecs) {
        const config = {
          codec,
          hardwareAcceleration: acceleration,
          width: options.width,
          height: options.height,
          bitrate: options.videoBitrate,
          //bitrateMode: options.cbr ? 'constant' : 'variable',
          framerate: options.fps,
          latencyMode: 'realtime',
        };
        const supported = await VideoEncoder.isConfigSupported(config as VideoEncoderConfig);
        if (supported.supported) {
          supportedCodecs.video = codec;
          supportedCodecs.videoConfig = supported.config;
          console.log('video codec', codec, supported.config);
          break;
        }
      }
      if (supportedCodecs.video !== '') break;
    }

    for (const codec of this.detectAudioCodecs) {
      const config = {
        codec,
        sampleRate: 48000,
        bitrate: options.audioBitrate,
        numberOfChannels: 2,
      };
      const supported = await AudioEncoder.isConfigSupported(config);
      if (supported.supported) {
        supportedCodecs.audio = codec;
        supportedCodecs.audioConfig = supported.config;
        console.log('audio codec', codec, supported.config);
        break;
      }
    }

    api().sendCodecDebug('support', supportedCodecs);

    return supportedCodecs;
  }

  private trackState: Array<NLPCodecState> = [];

  constructor(options: NLPCaptureWorkerOptions) {
    super(options);
    this.type = 'WebCodecs';
    this.captureStream = null;
  }

  private typeIsVideo(t: MediaTrackType) {
    return t === MediaTrackType.Video || t === MediaTrackType.VideoLQ;
  }

  private createEncoder(
    type: MediaTrackType,
    mediaTrack: MediaStreamVideoTrack | MediaStreamAudioTrack,
    trackId: number,
    options: any, //eslint-disable-line
    keyInt: number
  ): void {
    const state: NLPCodecState = {
      counter: 0,
      trackId: trackId,
      type: type,
      keyInt: keyInt,
      ts0: -1,
      forceKeyFrame: false,
      extraData: new Uint8Array(),
      config: options,
      lastTimestamp: 0,
    };

    if (this.typeIsVideo(type)) {
      state.encoder = new VideoEncoder({
        output: this.packetOutput.bind(this, state),
        error: this.encoderError.bind(this, `track${trackId}`, state),
      } as VideoEncoderInit);

      state.encoder.configure(options as VideoEncoderConfig);
      api().sendCodecDebug(`init${this.camera}-${trackId}`, {
        state: state.encoder.state,
        ...options,
      });
      if (state.encoder.state !== 'configured') {
        options.hardwareAcceleration = undefined;
        options.latencyMode = undefined;
        options.bitrate = 2_000_000;
        console.log('reconfigure codec with options', options);

        state.encoder = new VideoEncoder({
          output: this.packetOutput.bind(this, state),
          error: this.encoderError.bind(this, `track${trackId}`, state),
        } as VideoEncoderInit);

        state.encoder.configure(options as VideoEncoderConfig);
        api().sendCodecDebug(`init${this.camera}-${trackId}compat`, {
          state: state.encoder.state,
          ...options,
        });
      }
    } else {
      state.encoder = new AudioEncoder({
        output: this.packetOutput.bind(this, state),
        error: this.encoderError.bind(this, `track${trackId}`, state),
      } as AudioEncoderInit);

      state.encoder.configure(options as AudioEncoderConfig);
    }

    if (state.encoder === undefined) throw new Error(`Can't create encoder for ${type}`);

    if (this.typeIsVideo(type)) {
      state.trackProcessor = new MediaStreamTrackProcessor({ track: mediaTrack as MediaStreamVideoTrack });
    } else {
      state.trackProcessor = new MediaStreamTrackProcessor({ track: mediaTrack as MediaStreamAudioTrack });
    }

    state.stream = new WritableStream({
      write: this.writePacket.bind(this, state),
    });

    state.trackProcessor.readable.pipeTo(state.stream);

    this.trackState[trackId] = state;
  }

  async start(captureStream: MediaStream) {
    if (this.captureStream !== null) throw new Error('Already started');

    if (!this.options.width || !this.options.height) throw new Error('width or height are not defined');

    const audioTrack = captureStream.getAudioTracks()[0];
    const videoTrack = captureStream.getVideoTracks()[0];

    if (!videoTrack) throw new Error('Video track not available');

    const lqStream = captureStream.clone();
    this.refStreams.add(lqStream);
    const lqVideoTrack = lqStream.getVideoTracks()[0];

    await super.start(captureStream);

    const hardwareAcceleration = this.options.preferSoftware === true ? 'prefer-software' : 'prefer-hardware';

    console.log('video encode acceleration', hardwareAcceleration);

    //prettier-ignore
    this.createEncoder(
      MediaTrackType.Video,
      videoTrack,
      0,
      {
        codec: this.options.videoCodec,
        width: this.options.width,
        height: this.options.height,
        bitrate: this.options.videoBitrate,
        hardwareAcceleration: hardwareAcceleration,
        framerate: this.options.fps,
        alpha: 'discard',
        latencyMode: 'realtime',
      },
      this.options.fps||30
    );

    if (audioTrack) {
      const settings = audioTrack.getSettings();
      //prettier-ignore
      this.createEncoder(
        MediaTrackType.Audio,
        audioTrack,
        1,
        {
          codec: this.options.audioCodec,
          numberOfChannels: settings.channelCount,
          sampleRate: settings.sampleRate,
          bitrate: this.options.audioBitrate,
        },
        0
      );
    }

    //prettier-ignore
    this.createEncoder(
      MediaTrackType.VideoLQ,
      lqVideoTrack,
      2,
      {
        codec: this.options.videoCodec,
        width: appSettings.proxyVideo.size.width,
        height: appSettings.proxyVideo.size.height,
        bitrate: appSettings.proxyVideo.bitrate,
        hardwareAcceleration: hardwareAcceleration,
        framerate: this.options.fps,
        alpha: 'discard',
        latencyMode: 'realtime',
      },
      this.options.fps||30
    );
  }

  stop() {
    if (this.captureStream === null) return;
    super.stop();
    this.trackState.forEach((state) => {
      if (state?.encoder?.state !== 'closed') {
        state?.encoder?.close();
      }
    });
    this.trackState.splice(0, this.trackState.length - 1);
  }

  pushKeyFrame() {
    this.trackState.forEach((track) => {
      if (this.typeIsVideo(track.type)) track.forceKeyFrame = true;
    });
  }

  //eslint-disable-next-line
  writePacket(state: NLPCodecState, packet: any) { 
    if (state.encoder == null || state.encoder.state === 'closed') {
      packet.close();
      return;
    }

    if (state.ts0 === -1) {
      state.ts0 = packet.timestamp;
    }

    //if (state.trackId === 0) console.log(packet.timestamp - state.lastTimestamp);
    state.lastTimestamp = packet.timestamp;

    ++state.counter;
    if (this.typeIsVideo(state.type)) {
      //prettier-ignore
      const keyFrame = state.forceKeyFrame || (state.keyInt !== 0 && (state.counter % state.keyInt) === 0);
      if (keyFrame === true) state.forceKeyFrame = false;
      state.encoder.encode(packet, { keyFrame: keyFrame });
    } else {
      state.encoder.encode(packet);
    }
    packet.close();
  }

  //prettier-ignore
  packetOutput(
    state: NLPCodecState,
    packet: EncodedVideoChunk | EncodedAudioChunk,
    meta: EncodedVideoChunkMetadata | EncodedAudioChunkMetadata
  ) {
    let saveExtraData;

    if (state.ts0 === -1) {
      state.ts0 = packet.timestamp;
    }

    const ts = packet.timestamp - state.ts0;
    const pts = Math.round(ts / 1000);
    const dt = this.getWallTime(pts, 1000);

    if (state.extraData.length === 0 && meta.decoderConfig !== undefined && meta.decoderConfig.description !== undefined) {
      const extraData = meta.decoderConfig.description as Uint8Array;
      console.log('track', state.trackId, 'extradata', extraData);
      if (extraData?.byteLength) {
        saveExtraData = extraData.slice(0);
        state.extraData = extraData.slice(0);
      }
    }

    if (saveExtraData === undefined && pts === 0 && (meta.decoderConfig?.codec === 'vp8' || meta.decoderConfig?.codec === 'vp9')) {
      saveExtraData = new ArrayBuffer(0);
    }

    if (saveExtraData !== undefined) {
      const codecName = meta.decoderConfig?.codec || (this.typeIsVideo(state.type) ? this.options.videoCodec : this.options.audioCodec);
      const codecBitrate = (this.typeIsVideo(state.type) ? this.options.videoBitrate : this.options.audioBitrate);
      const codecInfo: NLPCodecInfo = {
        name: codecName,
        bitrate: codecBitrate,
        dt0: dt,
      };

      if (state.type === MediaTrackType.Video) {
        codecInfo.width = this.options.width;
        codecInfo.height = this.options.height;
        codecInfo.fps = this.options.fps;
      } else
      if (state.type === MediaTrackType.VideoLQ) {
        codecInfo.width = appSettings.proxyVideo.size.width;
        codecInfo.height = appSettings.proxyVideo.size.height;
        codecInfo.fps = this.options.fps;
      } else
      if (state.type === MediaTrackType.Audio) {
        const cfg = state.config as AudioEncoderConfig;
        codecInfo.audioChannels = cfg.numberOfChannels;
        codecInfo.audioSampleRate = cfg.sampleRate;
      }
       
      const te = new TextEncoder();
      const codecInfoBinary = te.encode(JSON.stringify(codecInfo)).buffer;

      this.db.addRecord({
        camera: this.camera,
        track: state.trackId,
        type: state.type,
        pts: -2,
        dt: -2, 
        duration: 0,
        is_key: true,
        data: codecInfoBinary,
        projectId: this.options.projectId,
        upload_ok: false,
      }, true);

      this.db.addRecord({
        camera: this.camera,
        track: state.trackId,
        type: state.type,
        pts: -1,
        dt: -1, 
        duration: 0,
        is_key: true,
        data: saveExtraData,
        upload_ok: false,
      }, true);
    }

    const data = new ArrayBuffer(packet.byteLength);
    packet.copyTo(data);
    this.db.addRecord({
      camera: this.camera,
      track: state.trackId,
      type: state.type,
      pts: pts,
      dt: dt,
      duration: packet.duration !== null ? (packet.duration / 1000) | 0 : 0,
      is_key: packet.type === 'key',
      data: data,
      upload_ok: false,
    }, false);
  }
}

export class NLPVideoCapture {
  options: NLPCaptureWorkerOptions;
  worker: NLPCaptureWorker | null = null;

  constructor(options: NLPCaptureWorkerOptions) {
    if (!options.width || !options.height || !options.videoBitrate || !options.audioBitrate)
      throw new Error('Bad options');
    this.options = options;
    if (!this.options.fps) this.options.fps = 30;
    if (this.options.cbr === undefined) this.options.cbr = true;
  }

  async detect() {
    if (
      window.VideoEncoder !== undefined &&
      window.AudioEncoder !== undefined &&
      window.MediaStreamTrackProcessor !== undefined &&
      window.WritableStream !== undefined &&
      window.MediaStreamTrackProcessor.prototype.hasOwnProperty('readable')) { // eslint-disable-line
      console.log('Try detect available codecs for MediaCodecs');
      const detectedCodecs = await NLPCaptureWorker_WebCodecs.detectCodecs(this.options);
      console.log('detected codecs', detectedCodecs);
      if (detectedCodecs.video === null || detectedCodecs.audio === null) {
        console.log('WebCodecs api does not support required codecs - fallback to MediaRecorder');
      } else {
        this.options.videoCodec = detectedCodecs.video;
        this.options.audioCodec = detectedCodecs.audio;
        if (detectedCodecs.videoConfig?.hardwareAcceleration === 'prefer-software') {
          this.options.preferSoftware = true;
        }
        this.worker = new NLPCaptureWorker_WebCodecs(this.options);
        return true;
      }
    }

    /*
    const codecMimeMap = new Map([
      ['video/webm;codecs="avc1.42001e,avc1.42401e,avc1.4d002a,mp4a.67"', NLPCaptureWorker_MediaRecorder_WebM],
      ['video/webm;codecs="avc1.42001e,avc1.42401e,avc1.4d002a,opus"', NLPCaptureWorker_MediaRecorder_WebM],
      ['video/webm;codecs="vp8,opus"', NLPCaptureWorker_MediaRecorder_WebM],
      ['video/webm', NLPCaptureWorker_MediaRecorder_WebM],
      ['video/mp4;codecs="avc1.4d002a,mp4a.67"', NLPCaptureWorker_MediaRecorder_MP4],
      ['video/mp4;codecs="avc1.42001e,mp4a.67"', NLPCaptureWorker_MediaRecorder_MP4],
      ['video/mp4;codecs="avc1.4d002a,opus"', NLPCaptureWorker_MediaRecorder_MP4],
      ['video/mp4;codecs="avc1.42001e,opus"', NLPCaptureWorker_MediaRecorder_MP4],
      ['video/mp4', NLPCaptureWorker_MediaRecorder_MP4],
    ]);

    for (const [id, cls] of codecMimeMap.entries()) {
      if (MediaRecorder.isTypeSupported(id)) {
        this.worker = new cls(this.options, id);
        break;
      }
    }
    */

    if (this.worker === null) throw new Error('Can\'t create video recorder: codecs not supported'); // eslint-disable-line

    return true;
  }

  get workerType() {
    if (this.worker === null) return 'NullWorker';
    return this.worker.type;
  }

  preStart(): Promise<void> {
    if (this.worker === null) throw new Error('No worker created');
    return this.worker.preStart();
  }

  start(captureStream: MediaStream, masterDt0?: number): Promise<void> {
    if (this.worker === null) throw new Error('No worker created');
    return this.worker.start(captureStream.clone(), masterDt0);
  }

  stop() {
    if (this.worker === null) throw new Error('No worker created');
    return this.worker.stop();
  }

  setTimeCorrection(value: number) {
    if (this.worker !== null) this.worker.timeCorrection = value;
  }

  pushKeyFrame() {
    if (this.worker !== null) this.worker.pushKeyFrame();
  }
}

/*

class NLPCaptureWorker_MediaRecorder extends NLPCaptureWorker {
  constructor(options, mimeType) {
    super(options);
    this.mimeType = mimeType;
    this.type = 'MediaRecorder';
    this.rec = null;
    this.dataTimer = null;
  }

  start(captureStream) {
    this.rec = new MediaRecorder(captureStream, {
      mimeType: this.mimeType,
      audioBitsPerSecond: this.options.audioBitrate,
      videoBitsPerSecond: this.options.videoBitrate,
    });
    this.rec.addEventListener('dataavailable', this.processData.bind(this));
    this.rec.addEventListener('start', this.internalStart.bind(this), { once: true });
    this.rec.addEventListener('stop', this.internalStop.bind(this), { once: true });
    this.rec.start();
  }

  internalStart() {
    super.start();
    this.dataTimer = window.setInterval(this.recorderRequestData.bind(this));
  }

  internalStop() {
    window.clearInterval(this.dataTimer);
    this.dataTimer = null;
    super.stop();
  }

  recorderRequestData() {
    if (this.rec !== null) this.rec.requestData();
  }

  stop() {
    if (this.rec === null) return;
    this.rec.stop();
  }
}

class NLPCaptureWorker_MediaRecorder_WebM extends NLPCaptureWorker_MediaRecorder {
  constructor(options, mimeType) {
    super(options, mimeType);
    this.type = 'MediaRecorder/WebM';
    this.demuxer = new mkvdemuxjs.MkvDemux();
  }

  async processData(event) {
    console.log(event.data);
    if (event.data.size < 2 || this.db === null) return;
    this.demuxer.push(await event.data.arrayBuffer());
    for (let part = this.demuxer.demux(); part !== null; part = this.demuxer.demux()) {
      if (part.track !== undefined) {
        this.db.putHeader({
          track: part.track.number,
          data: part.track,
        });
      }
      if (Array.isArray(part.frames)) {
        part.frames.forEach((frame) => {
          this.db.putFrame({
            track: frame.track,
            pts: frame.timestamp,
            dt: this.getWallTime((frame.timestamp * 1000) | 0, 1000),
            is_key: frame.is_key,
            data: frame.data,
          });
        });
      }
    }
  }
}

class NLPCaptureWorker_MediaRecorder_MP4 extends NLPCaptureWorker_MediaRecorder {
  constructor(options, mimeType) {
    super(options, mimeType);
    this.type = 'MediaRecorder/MP4';
    this.demuxer = MP4Box.createFile(false);
    this.demuxer.onError = this.demuxerError.bind(this);
    this.demuxer.onReady = this.demuxerReady.bind(this);
    this.demuxer.onSamples = this.demuxerSample.bind(this);
    this.currentFilePos = 0;
  }

  async processData(event) {
    console.log(event.data);
    const ab = await event.data.arrayBuffer();
    ab.fileStart = this.currentFilePos;
    this.currentFilePos += event.data.size;
    this.demuxer.appendBuffer(ab);
  }

  demuxerReady(info) {
    console.log('ready', info);
    if (!info) return;
    if (Array.isArray(info.tracks)) {
      info.tracks.forEach((track) => {
        this.demuxer.setExtractionOptions(track.id, null, { nbSamples: 1000 });
        if (this.db !== null) {
          const trak = this.demuxer.extractedTracks.find((t) => t.id === track.id)?.trak;
          const stsd_box = trak?.mdia?.minf?.stbl?.stsd?.entries[0];
          let extradata;
          try {
            if (stsd_box.type === 'avc1') {
              extradata = {
                pps: stsd_box.avcC.PPS[0].nalu,
                sps: stsd_box.avcC.SPS[0].nalu,
              };
            } else
              if (stsd_box.type === 'mp4a') {
                extradata = {
                  data: stsd_box.esds.data
                };
              }
          } catch (err) {
            console.log('Can\'t access MP4 box', err);
          }
          this.db.putHeader({
            track: track.id,
            data: {
              type: track.type,
              codec: track.codec,
              bitrate: track.bitrate,
              video: track.video,
              audio: track.audio,
              timescale: track.timescale,
              extradata: extradata,
            }
          });
        }
      });
      this.demuxer.start();
    }
  }

  async demuxerSample(id, user, samples) {
    console.log('sample', id, samples);
    //console.log(this.demuxer);
    if (samples.length === 0 || this.db === null) return;
    samples.forEach((sample) => {
      this.db.putFrame({
        track: id,
        pts: sample.dts,
        dt: this.getWallTime(sample.dts, sample.timescale),
        is_key: sample.is_sync,
        data: new Blob(sample.data),
      });
    });
    this.demuxer.releaseUsedSamples(id, samples.pop().number);
  }
}
*/

const maximumUploadBodySize = 2_000_000;

interface UploadResult {
  packets: number;
  bytes: number;
  error: boolean;
}

export class NLPVideoUpload {
  db: NLPVideoCaptureDatabase;

  constructor(projectId: string) {
    this.db = new NLPVideoCaptureDatabase(projectId);
  }

  async open() {
    await this.db.open();
  }

  private async sendPacketBuffer(
    camera: number,
    list: Array<NLPVideoCaptureDatabaseRecord>,
    uploadBurst: number
  ): Promise<UploadResult> {
    let fd = new FormData();
    let fd_size = 0;
    const uploadResult: UploadResult = {
      bytes: 0,
      packets: 0,
      error: false,
    };
    let blockSize = 0;
    for (let i = 0; i < list.length; ++i) {
      const pkt = list[i];
      fd.append(
        `t_${pkt.dt}_${pkt.track}_${pkt.type}_${pkt.is_key ? 'K' : 'F'}_${pkt.pts}_${pkt.duration}`,
        new Blob([pkt.data], { type: 'application/octet-stream' })
      );
      uploadResult.bytes += pkt.data.byteLength;
      ++uploadResult.packets;
      blockSize += pkt.data.byteLength;
      ++fd_size;
      if (fd_size === uploadBurst || blockSize > maximumUploadBodySize) {
        appEvents.emit('app:uploadPreSend', camera, fd_size, blockSize, uploadResult.bytes, uploadResult.packets);
        for (let tr = 0; tr < 10; ++tr) {
          try {
            await api().sendDebug(`upload${camera}`, { try: tr, size: fd_size });
            const ur = await api().uploadCameraPackets(camera, fd);
            console.log('upload api call res', ur);
            if (ur !== null) break;
            else {
              await api().sendDebug(`upload${camera}-error`, { try: tr, size: fd_size });
            }
          } catch (err) {
            console.log('error when upload packets', err);
            throw new Error('Upload error');
          }
        }
        appEvents.emit('app:uploadPostSend', camera, fd_size, blockSize, uploadResult.bytes, uploadResult.packets);
        fd_size = 0;
        blockSize = 0;
        fd = new FormData();
      }
    }
    if (fd_size > 0) {
      appEvents.emit('app:uploadPreSend', camera, fd_size, blockSize, uploadResult.bytes, uploadResult.packets);
      for (let tr = 0; tr < 10; ++tr) {
        try {
          const ur = await api().uploadCameraPackets(camera, fd);
          console.log('upload api call res', ur);
          if (ur !== null) break;
        } catch (err) {
          console.log('error when upload packets', err);
          throw new Error('Upload error');
        }
      }
      appEvents.emit('app:uploadPostSend', camera, fd_size, blockSize, uploadResult.bytes, uploadResult.packets);
    }
    return uploadResult;
  }

  async uploadRange(camera: number, ranges: Array<NLPTimeRange>, uploadBurst = 100): Promise<UploadResult> {
    let packetCount = 0;

    const store = StoreUtils.store;
    console.log(store, 'store videocapture');

    for (let i = 0; i < ranges.length; ++i) {
      const R = ranges[i];
      packetCount += await this.db.countRange(camera, R.start, R.stop);
    }

    appEvents.emit('app:uploadStart', camera, packetCount);

    store.dispatch('upload/updateLocalUploadState', {
      camera: camera,
      active: true,
      current: 0,
      total: packetCount,
      error: false,
    });

    const res: UploadResult = {
      bytes: 0,
      packets: 0,
      error: false,
    };

    try {
      for await (const packets of this.db.walkPacketsRanges(camera, ranges)) {
        const r = await this.sendPacketBuffer(camera, packets, uploadBurst);
        res.bytes += r.bytes;
        res.packets += r.packets;
        store.dispatch('upload/updateLocalUploadProgress', {
          camera: camera,
          value: res.packets,
          active: true,
          error: false,
        });
        appEvents.emit('app:uploadProgress', camera, packetCount, r, res);
      }

      store.dispatch('upload/updateLocalUploadProgress', {
        camera: camera,
        value: res.packets,
        active: false,
        error: false,
      });
      appEvents.emit('app:uploadDone', camera, packetCount, res, false);
    } catch (err) {
      console.log('error in uploadRange', err);
      store.dispatch('upload/updateLocalUploadProgress', {
        camera: camera,
        value: res.packets,
        active: false,
        error: true,
      });
      appEvents.emit('app:uploadDone', camera, packetCount, res, true);
      res.error = true;
    }
    return res;
  }

  async uploadAll(camera: number, uploadBurst = 100): Promise<UploadResult> {
    console.log('uploadAll', Date.now());
    const store = StoreUtils.store;
    console.log('uploadAll store', Date.now());
    const packetCount = await this.db.countAll(camera);
    console.log('uploadAll packetCount', packetCount, Date.now());

    await api().sendDebug(`uploadAll${camera}-count`, { cnt: packetCount });

    appEvents.emit('app:uploadStart', camera, packetCount);

    store.dispatch('upload/updateLocalUploadState', {
      camera: camera,
      active: true,
      current: 0,
      total: packetCount,
      error: false,
    });

    const res: UploadResult = {
      bytes: 0,
      packets: 0,
      error: false,
    };

    try {
      for await (const packets of this.db.walkPacketsRaw(camera, 0, uploadBurst)) {
        const r = await this.sendPacketBuffer(camera, packets, uploadBurst);
        res.bytes += r.bytes;
        res.packets += r.packets;
        store.dispatch('upload/updateLocalUploadProgress', {
          camera: camera,
          value: res.packets,
          active: true,
          error: false,
        });
        appEvents.emit('app:uploadProgress', camera, packetCount, r, res);
      }
      store.dispatch('upload/updateLocalUploadProgress', {
        camera: camera,
        value: res.packets,
        active: false,
        error: false,
      });
      appEvents.emit('app:uploadDone', camera, packetCount, res, false);
    } catch (err) {
      console.log('error in uploadAll', err);
      store.dispatch('upload/updateLocalUploadProgress', {
        camera: camera,
        value: res.packets,
        active: false,
        error: true,
      });
      appEvents.emit('app:uploadDone', camera, packetCount, res, true);
      res.error = true;
    }

    await api().sendDebug(`uploadAll${camera}-res`, res);

    return res;
  }
}
