import { createApp } from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';

import '@/assets/styles/base.scss';
import '@/assets/styles/wasd.scss';

import { createApi, api } from './utils/api';

import appEvents from '@/utils/events';
import StoreUtils from '@/utils/store';

if (process.env.VUE_APP_AUTH) {
  createApi(process.env.VUE_APP_AUTH);
} else {
  await createApi();
}

if (process.env.VUE_APP_PROJECTAUTH) {
  api().setProjectAuth(process.env.VUE_APP_PROJECTAUTH, process.env.VUE_APP_PROJECTAUTH_CAMERA);
}

//prettier-ignore
console.log(
  'git version',
  process.env.VUE_APP_GIT_HASH,
  process.env.VUE_APP_BUILD_DATE
);

appEvents.on('app:checkVersion', () => {
  if (window.matchMedia('(display-mode: standalone)').matches) return;
  fetch(`/version.txt?${Math.random().toString(36).substring(3)}`)
    .then((res) => res.text())
    .then((version) => {
      console.log('remote version', version);
      if (process.env.VUE_APP_GIT_HASH !== version) {
        alert('App version mismatch');
        window.location.reload();
      }
    });
});

appEvents.emit('app:start');

createApp(App).use(store).use(router).mount('#app');

StoreUtils.store = store;
