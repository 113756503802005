/*
Работа с базой данных для варианта когда будет ремуксинг видеопотока

interface MediaFileRecord {
  id?:number;
  fragment:number;
  dt:number;
  data:Blob;
}

const MediaRecorderDatabaseVersion=1;

class MediaRecorderDatabase {
  private db:IDBDatabase|null=null;
  private name:string;

  constructor(name:string) {
    this.name=name;
  }

  async open() {
    const db_list=await window.indexedDB.databases();
    if (db_list.some((db)=>db.name===this.name)) await this.deleteDatabase();
    await this.openDatabase();
  }

  private deleteDatabase() {
    return new Promise((resolve, reject)=>{
      const delReq=window.indexedDB.deleteDatabase(this.name);
      delReq.addEventListener('error', reject);
      delReq.addEventListener('success',resolve);
    });
  }

  private openDatabase():Promise<MediaRecorderDatabase> {
    return new Promise((resolve, reject)=>{
      const openReq=window.indexedDB.open(this.name,MediaRecorderDatabaseVersion);
      openReq.addEventListener('error', reject);
      openReq.addEventListener('upgradeneeded',()=>{
        const db=openReq.result;
        db.addEventListener('error',reject);
        db.createObjectStore('file',{keyPath:'id',autoIncrement:true});
      });
      openReq.addEventListener('success',()=>{
        this.db=openReq.result;
        resolve(this);
      });
    });
  }

  private getStore():IDBObjectStore {
    if (this.db===null) throw new Error('Database not opened');
    const T:IDBTransaction=this.db.transaction(['file'],'readwrite');
    return T.objectStore('file');
  }

  private waitForOp(request:IDBRequest) {
    return new Promise((resolve, reject)=>{
      request.addEventListener('error', reject);
      request.addEventListener('success', resolve);
    });
  }

  async clear() {
    if (this.db===null) throw new Error('Database not opened');
    const store=this.getStore();
    if (!store) throw new Error('Can\'t get database store');
    await this.waitForOp(store.clear());
  }

  addFragment(data:MediaFileRecord) {
    if (this.db===null) throw new Error('Database not opened');
    const store=this.getStore();
    if (!store) throw new Error('Can\'t get database store');

    return this.waitForOp(store.add(data));
  }
}
*/

export class NLPMediaRecorder {
  name = '';
  private dvr: MediaRecorder | null = null;
  private chunks: Array<Blob> = [];

  constructor(name: string) {
    this.name = name;
    this.ev_dataavailable = this.ev_dataavailable.bind(this);
  }

  start(stream: MediaStream) {
    if (this.dvr !== null) {
      try {
        this.dvr.stop();
        this.dvr = null;
        this.chunks.splice(0, this.chunks.length);
      } catch (err) {
        false;
      }
    }

    const options = { mimeType: 'video/webm;codecs=vp8,opus' };
    // if (MediaRecorder.isTypeSupported('video/webm;codecs=avc1.4d002a,opus')) {
    //   options = { mimeType: 'video/webm;codecs=avc1.4d002a,opus' };
    // } else {
    //   options = { mimeType: 'video/webm;codecs=vp8,opus' };
    // }
    console.log(options);
    this.dvr = new MediaRecorder(stream, {
      audioBitsPerSecond: 64000,
      videoBitsPerSecond: 512000,
      ...options,
    });
    this.dvr.addEventListener('dataavailable', this.ev_dataavailable);
    this.dvr.start(1000);
  }

  stop(): Blob | null {
    if (this.dvr === null) return null;
    const mimeType = this.dvr.mimeType;

    this.dvr.stop();

    const res = new Blob(this.chunks, { type: mimeType });

    this.dvr = null;
    this.chunks.splice(0, this.chunks.length);

    return res;
  }

  ev_dataavailable(ev: BlobEvent) {
    this.chunks.push(ev.data);
  }
}
