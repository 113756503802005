
import { ref, computed, defineComponent, reactive, Ref, onMounted, onBeforeUnmount } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { api } from '@/utils/api';
import { useWebrtc } from '@/composables/useWebrtc';
import { useCommon } from '@/composables/useCommon';

import Icon from '@/components/common/icon/Icon.vue';
import RecButton from '@/components/common/RecButton.vue';
import ReaderQr from '@/components/common/qr/ReaderQr.vue';
import ModalSelectDevice from '@/components/modals/ModalSelectDevice.vue';
import { useStore } from 'vuex';
import ProgressBar from '@/components/common/ProgressBar.vue';
import RangeInput from '@/components/common/inputs/RangeInput.vue';

export default defineComponent({
  components: {
    Icon,
    RecButton,
    ReaderQr,
    ModalSelectDevice,
    ProgressBar,
    RangeInput,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const camera = computed(() => {
      return parseInt('' + route?.params?.camera);
    });
    const {
      createConnection,
      local_video,
      local_time,
      remote_time,
      border_color,
      isRecording,
      isLoadingVideo,
      stop: connectionStop,
      isLoadedVideo,
      zoomParams,
    } = useWebrtc();
    const { memory } = useCommon();

    const store = useStore();

    const isShowReadQr: Ref<boolean> = ref(false);

    const stateButtons = reactive({ reverse: false, mic: false, mute: false });

    const progressPercent = computed(() => {
      const localCam = store.state.upload.local[camera.value];
      return Math.round((localCam.current * 100) / localCam.total) || 0;
    });

    const clickButton = (button: string) => {
      stateButtons[button as keyof typeof stateButtons] = !stateButtons[button as keyof typeof stateButtons];
    };

    const buttonRecType = computed(() => {
      if (isShowReadQr.value) return 'inactive';
      if (isRecording.value) return 'started';
      return 'stopped';
    });

    const isShowModalDevice = ref<boolean>(false);

    async function isCameraAllowed(): Promise<boolean> {
      // сделано так из-за того что PermissionName в ts не имеет вариантов camera, microphone
      //eslint-disable-next-line
      const query = (p: any) => navigator.permissions.query(p);
      const allowVideo = (await query({ name: 'camera' }))?.state === 'granted';
      const allowAudio = (await query({ name: 'microphone' }))?.state === 'granted';
      return allowVideo && allowAudio;
    }

    onMounted(async () => {
      if (camera.value) {
        try {
          if (!(await isCameraAllowed())) {
            const stream = await navigator.mediaDevices.getUserMedia({ audio: true, video: true });
            stream.getTracks().forEach((track) => {
              track.stop();
            });
          }
          isShowModalDevice.value = true;
        } catch (error) {
          store.dispatch('modals/getIsShowModalError', { value: true, text: 'нет доступных устройств' });
        }
      } else {
        isShowReadQr.value = true;
      }
    });

    const close = () => {
      router.push('/');
    };

    const selectCamera = async ({ videoInput, audioInput }: { videoInput: string; audioInput: string }) => {
      isShowReadQr.value = false;
      if ((await api().projectDirectorOnline()) === -1) {
        store.dispatch('modals/getIsShowModalError', { value: true });
        isShowModalDevice.value = false;
        return;
      }
      createConnection(camera.value, null, { videoInput, audioInput });
      isShowModalDevice.value = false;
    };

    onBeforeUnmount(async () => {
      connectionStop();
    });

    const open = () => {
      router.push('/');
    };

    const decode = async (str: string) => {
      try {
        isShowReadQr.value = false;
        const code = str.replace(`${location.origin}/qr/`, '');
        const data = await api().checkQrCode(code);
        if (!data || data.camera < 1 || data.camera > 8) return;
        await api().setProjectAuth(`${data.id}:${data.auth}`, data.camera);
        /*
        так делать нельзя, так как при релоаде теряется камера
        createConnection(data?.camera);
        */
        // Переходим на страницу с уже казанной камерой
        router.replace({ path: `/operator/${data.camera}` });
        isShowModalDevice.value = true;
      } catch (error) {
        console.log(error);
      }
    };

    return {
      open,
      clickButton,
      stateButtons,
      isShowReadQr,
      decode,
      local_video,
      local_time,
      remote_time,
      border_color,
      isLoadingVideo,
      isRecording,
      buttonRecType,
      isShowModalDevice,
      selectCamera,
      memory,
      close,
      isLoadedVideo,
      progressPercent,
      zoomParams,
    };
  },
});
