import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2d33f7b8"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "video-timeline",
  ref: "timelineContainer"
}
const _hoisted_2 = { class: "video-timeline__container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VideoFrames = _resolveComponent("VideoFrames")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: "video-timeline__linear",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.setVideoTime && _ctx.setVideoTime(...args))),
        ref: "linearEl"
      }, [
        _createElementVNode("div", {
          class: "video-timeline__seeker",
          style: _normalizeStyle(`left: ${_ctx.currentPosition}px`)
        }, null, 4),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.timelineLength, (item, index) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "video-timeline__linear-item",
            key: index
          }, [
            _createElementVNode("span", null, _toDisplayString(item?.m || '00') + "." + _toDisplayString(item?.s || '00'), 1)
          ]))
        }), 128))
      ], 512),
      (_ctx.videos.length)
        ? (_openBlock(), _createBlock(_component_VideoFrames, {
            key: 0,
            videos: _ctx.videos,
            duration: _ctx.moiveDuration
          }, null, 8, ["videos", "duration"]))
        : _createCommentVNode("", true)
    ])
  ], 512))
}