
import { defineComponent, ref } from 'vue';
import { QrStream } from 'vue3-qr-reader';
// import  from '../../../dist/vue3-qr-reader.common'

// import { init, decompress } from '@bokuweb/zstd-wasm';

export default defineComponent({
  name: 'ReqderQr',
  components: {
    QrStream,
  },
  setup(props, { emit }) {
    const result: any = ref('');
    const onDecode = async (str: any) => {
      // await init();
      // const arr = new Uint8Array(str.split(','));
      // console.log(arr);
      // const decompressed = decompress(arr);
      // const decoder = new TextDecoder();
      // result.value = decoder.decode(decompressed);
      emit('decode', str);
    };

    const onInit = (promise: any) => {
      promise.then(console.log).catch(console.error);
    };

    return {
      onDecode,
      onInit,
      result,
    };
  },
});
