
import { defineComponent, onMounted } from 'vue';
import ButtonUser from '@/components/common/buttons/Button.vue';
import Icon from '@/components/common/icon/Icon.vue';
import AppVersion from '@/components/common/AppVersion.vue';
import { useRouter } from 'vue-router';
import PwaButton from '@/components/PwaButton.vue';

export default defineComponent({
  name: 'HomeView',
  components: {
    ButtonUser,
    Icon,
    AppVersion,
    PwaButton,
  },
  setup() {
    const router = useRouter();

    onMounted(() => {
      console.log('init project');
    });

    const routerPushProjects = () => {
      router.push('/producer-projects');
    };

    const openLoginOperator = () => {
      router.push('/operator');
    };

    return {
      routerPushProjects,
      openLoginOperator,
    };
  },
});
