export interface Modals {
  isShowModalError: boolean;
  isShowModalSuccess: boolean;
  errorText: string;
}

const state: Modals = {
  isShowModalError: false,
  isShowModalSuccess: false,
  errorText: '',
};

export type State = typeof state;
export default state;
