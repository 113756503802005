import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "producer-projects" }
const _hoisted_2 = { class: "producer-projects__header" }
const _hoisted_3 = { class: "producer-projects__header-controls" }
const _hoisted_4 = { class: "producer-projects__burger-menu" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_ButtonUser = _resolveComponent("ButtonUser")!
  const _component_VideoProject = _resolveComponent("VideoProject")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_Icon, {
          icon: "back",
          onClick: _ctx.close
        }, null, 8, ["onClick"]),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_Icon, { icon: "burger" })
        ])
      ]),
      _createVNode(_component_ButtonUser, {
        text: "Начать съемку",
        onClick: _ctx.openProject
      }, null, 8, ["onClick"])
    ]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.projects, (project, index) => {
      return (_openBlock(), _createBlock(_component_VideoProject, {
        class: "producer-projects__video",
        key: index,
        video: project
      }, null, 8, ["video"]))
    }), 128))
  ]))
}