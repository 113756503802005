import { ref } from 'vue';

interface Support {
  item: string;
  obj: object;
}

export function useSupportBrowser() {
  const checkIn = (value: string, obj: any) => {
    if (value in obj) return true;
    return false;
  };

  const checkSupportMethods: Support[] = [
    { item: 'VideoDecoder', obj: window },
    { item: 'VideoEncoder', obj: window },
    { item: 'RTCPeerConnection', obj: window },
    { item: 'mediaDevices', obj: navigator },
  ];

  const isErrorSupported = ref<boolean>(false);
  const listErrorSupport = ref<string[]>([]);

  const checkSupportBrowser = (): boolean => {
    checkSupportMethods.forEach(({ item, obj }) => {
      if (!checkIn(item, obj)) {
        isErrorSupported.value = true;
        listErrorSupport.value.push(item);
      }
    });
    return isErrorSupported.value;
  };

  const checkWebView = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    return userAgent.indexOf('wv') > -1 && userAgent.indexOf('chrome') === -1;
  };

  const checkIsSupportVersionChrome = () => {
    console.log(window.navigator.userAgent);
    const { userAgent } = navigator;
    const isMobile =
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(
        userAgent
      );

    if (userAgent.includes('Chrome/')) {
      const version = userAgent?.match(/Chrom(e|ium)\/([0-9]+)\./)?.[2] || '';
      console.log(version, isMobile);
      return isMobile ? +version < 114 : +version < 94;
    } else {
      return true;
    }
  };

  return {
    checkSupportBrowser,
    checkWebView,
    listErrorSupport,
    checkIsSupportVersionChrome,
  };
}
