
import { computed, defineComponent } from 'vue';
import CircleIconButton from '@/components/common/buttons/CircleIconButton.vue';

export default defineComponent({
  name: 'PreviewVideo',
  components: { CircleIconButton },
  props: {
    isConnected: {
      default: false,
      type: Boolean,
    },
    isShowSettings: {
      default: false,
      type: Boolean,
    },
    isShowChangeCam: {
      default: false,
      type: Boolean,
    },
    active: {
      default: false,
      type: Boolean,
    },
    flipVideo: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    console.log(props);
    return {};
  },
});
