
import { defineComponent, ref } from 'vue';

export default defineComponent({
  name: 'AppVersion',
  setup() {
    return {
      hash: ref<string>(process.env.VUE_APP_GIT_HASH.substring(0, 8) + process.env.VUE_APP_GIT_HASH.substr(-2)),
      date: ref<string>(process.env.VUE_APP_BUILD_DATE),
    };
  },
});
