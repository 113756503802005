interface Origin {
  width: number;
  height: number;
}

export const reduceSize = (origin: Origin, width: number) => {
  let newWidth = origin.width;
  let newHeight = origin.height;

  if (newWidth > width) {
    newWidth = width;
    newHeight = (origin.height * width) / origin.width;
  }

  return {
    width: newWidth,
    height: newHeight,
  };
};

export const getVideoFrames = async (src: string, duration: number): Promise<string[]> => {
  const canvas: HTMLCanvasElement = document.createElement('canvas');
  const ctx: CanvasRenderingContext2D | null = canvas.getContext('2d');
  const layerFrames: string[] = [];

  return new Promise((res) => {
    const videoEl = document.createElement('video');
    videoEl.src = src;
    // videoEl.crossOrigin = 'Anonymous';

    let i = 0;

    videoEl.addEventListener('loadeddata', async () => {
      const { width, height } = reduceSize({ width: videoEl.videoWidth, height: videoEl.videoHeight }, 100);
      canvas.width = width;
      canvas.height = height;
      // const duration = videoEl.duration;
      const countFrames = (duration * 100) / width;
      console.log(width, countFrames, duration);
      // const countFrames = duration;
      videoEl.currentTime = 0;

      videoEl.addEventListener('seeked', async () => {
        await ctx?.drawImage(videoEl, 0, 0, width, height);
        // const img = canvas.toDataURL('image/png');
        layerFrames.push(canvas.toDataURL());

        i = duration / countFrames + i;

        if (i <= videoEl.duration) {
          videoEl.currentTime = i;
        } else {
          canvas.remove();
          videoEl.remove();
          res(layerFrames);
        }
      });
    });
  });
};
