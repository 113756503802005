
import { PropType, Ref, computed, defineComponent, ref, toRefs } from 'vue';
import Icon from '@/components/common/icon/Icon.vue';
import { Project } from '@/store/projects/state';
import Modal from '@/components/common/Modal.vue';
import ButtonUser from '@/components/common/buttons/Button.vue';
import { useStore } from 'vuex';
import { api } from '@/utils/api';

export default defineComponent({
  name: 'VideoProject',
  components: {
    Icon,
    Modal,
    ButtonUser,
  },
  props: {
    video: Object as PropType<Project>,
  },
  setup(props) {
    const isShowModal: Ref<boolean> = ref(false);
    const { video } = toRefs(props);

    const date = computed(() => {
      const dt: any = video.value?.dt;
      const timestamp = new Date(dt);

      return {
        time: `${timestamp.getHours()}:${timestamp.getMinutes()}`,
        date: `${timestamp.toLocaleDateString('ru-RU')}`,
      };
    });

    const store = useStore();

    const deleteProject = () => {
      store.dispatch('projects/deleteProject', video.value?.id);
      isShowModal.value = false;
    };

    const downloadProject = async () => {
      isShowModal.value = false;
      if (!video.value?.id) return;

      const data = await api().downloadVideo(video.value?.id);
      if (data === null) {
        store.dispatch('modals/getIsShowModalError', { value: true });
        return;
      }

      if (typeof navigator.canShare === 'function') {
        const shareData = {
          files: [new File([data], 'video.mp4', { type: data.type })],
          title: video.value.name,
          text: 'Project video text',
        };
        if (navigator.canShare(shareData)) {
          await navigator.share(shareData);
        }
      } else {
        // создается ссылка по blob
        const url = URL.createObjectURL(data);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'video.mp4');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
      }
    };

    return {
      isShowModal,
      date,
      deleteProject,
      downloadProject,
    };
  },
});
