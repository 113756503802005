import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0a71cc84"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "progress__bar" }
const _hoisted_2 = { class: "progress__percent" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['progress', { 'progress_flex-column': _ctx.percentPosition === 'bottom' }])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", {
        class: "progress__line",
        style: _normalizeStyle(`width: ${_ctx.percent}%`)
      }, null, 4)
    ]),
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.percent || 0) + "%", 1)
  ], 2))
}