import { Ref, ref } from 'vue';

export interface Position {
  x: number;
  y: number;
}

export function useMouseDragForTrim() {
  const curentPosition: Ref<Position | null> = ref(null);
  const dragElem = ref();

  const mousedown = (event: any) => {
    dragElem.value = event.target;
  };

  const mousemove = (event: any) => {
    if (dragElem.value) {
      curentPosition.value = {
        x: event.clientX - event.currentTarget.getBoundingClientRect().left,
        y: event.clientY - event.currentTarget.getBoundingClientRect().top,
      };
    }
  };

  const mouseup = () => {
    dragElem.value = null;
  };

  return {
    curentPosition,
    mousedown,
    mousemove,
    mouseup,
    dragElem,
  };
}
