
import { defineComponent, onMounted, computed, Ref } from 'vue';
import VideoProject from '@/components/producer/VideoProject.vue';
import ButtonUser from '@/components/common/buttons/Button.vue';
import { useRouter } from 'vue-router';
import Icon from '@/components/common/icon/Icon.vue';
import { useStore } from 'vuex';
import { Project } from '@/store/projects/state';

export default defineComponent({
  components: {
    VideoProject,
    ButtonUser,
    Icon,
  },
  setup() {
    const router = useRouter();
    const store = useStore();

    const openProject = async () => {
      await store.dispatch('projects/createProject');
      router.push('/director');
    };

    onMounted(() => {
      store.dispatch('projects/getProjects');
    });

    const projects: Ref<Project[]> = computed(() => {
      return store.state.projects.projects;
    });

    const close = () => {
      router.push('/roles');
    };

    return {
      openProject,
      projects,
      close,
    };
  },
});
