
import { defineComponent, toRefs } from 'vue';
import Icon from '../icon/Icon.vue';
import { useThrottleFn } from '@vueuse/core';

export default defineComponent({
  inheritAttrs: false,
  name: 'ButtonRec',
  props: {
    disabled: {
      default: false,
      type: Boolean,
    },
    type: {
      default: 'start',
      type: String,
      validator: function (value: string) {
        return ['start', 'stop', 'inactive'].includes(value);
      },
    },
  },
  setup(props, { emit }) {
    const { type } = toRefs(props);

    const clickBtn = useThrottleFn(() => {
      console.log('clicl rec btn');
      if (type.value === 'start') return emit('startRecord');
      else if (type.value === 'stop') emit('stopRecord');
      emit('click');
    }, 500);

    return {
      clickBtn,
    };
  },
  components: { Icon },
});
