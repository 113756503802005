import { MutationTree } from 'vuex';
import { StateProject } from './state';

const mutations: MutationTree<StateProject> = {
  setProjectAuth(state, projectAuth: string) {
    if (!projectAuth) {
      state.currentProjectAuth = null;
      state.currentProjectId = null;
    } else {
      state.currentProjectAuth = projectAuth;
      state.currentProjectId = projectAuth.split(':')[0];
    }
  },
  setProjects(state, projects) {
    state.projects = projects;
  },
  setProject(state, project) {
    const index = state.projects.findIndex((item) => item.id === state.currentProjectId);
    state.projects[index] = { ...state.projects[index], ...project };
  },
  deleteProject(state, projectId: string) {
    const index = state.projects.findIndex((item) => item.id === projectId);
    if (index < 0) return;
    state.projects.splice(index, 1);
  },
};

export default mutations;
