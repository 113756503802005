import { createStore } from 'vuex';

import auth from './auth/index';
import projects from './projects';
import modals from './modals';
import upload from './upload';
import video from './video';

export default createStore({
  state: {
    isShowModalError: false,
    isPwa: window.matchMedia('(display-mode: standalone)').matches,
  },
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    auth,
    projects,
    modals,
    upload,
    video,
  },
  strict: false, // если включать - производительность ложится
});
