import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-46f7d061"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "video-project" }
const _hoisted_2 = { class: "video-project__content" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "video-project__info" }
const _hoisted_5 = { class: "video-project__title" }
const _hoisted_6 = { class: "video-project__date" }
const _hoisted_7 = { class: "video-project__size" }
const _hoisted_8 = { class: "video-project__button-menu" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_ButtonUser = _resolveComponent("ButtonUser")!
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("img", {
        src: `data:image/png;base64,${_ctx.video?.image}`,
        class: "video-project__preview"
      }, null, 8, _hoisted_3),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.video?.name), 1),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("span", null, _toDisplayString(_ctx.date?.date), 1),
          _createElementVNode("span", null, _toDisplayString(_ctx.date?.time), 1)
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("span", null, _toDisplayString((((_ctx.video?.duration as number) * 1) / 1000).toFixed(1)) + " с.", 1),
          _createElementVNode("span", null, _toDisplayString(_ctx.video?.size), 1)
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_8, [
      _createVNode(_component_Icon, {
        icon: "ellipsis",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isShowModal = true))
      })
    ]),
    _createVNode(_component_Modal, {
      showModal: _ctx.isShowModal,
      position: "bottom",
      onClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.isShowModal = false))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_ButtonUser, {
          type: "ghost",
          size: "m",
          text: "Редактировать",
          class: "video-project__menu-button"
        }),
        _createVNode(_component_ButtonUser, {
          type: "ghost",
          size: "m",
          text: "Переименовать",
          class: "video-project__menu-button"
        }),
        (_ctx.video?.render?.finished)
          ? (_openBlock(), _createBlock(_component_ButtonUser, {
              key: 0,
              type: "ghost",
              size: "m",
              text: "Скачать",
              class: "video-project__menu-button",
              onClick: _ctx.downloadProject
            }, null, 8, ["onClick"]))
          : _createCommentVNode("", true),
        _createVNode(_component_ButtonUser, {
          type: "ghost",
          size: "m",
          text: "Удалить",
          class: "video-project__menu-button",
          onClick: _ctx.deleteProject
        }, null, 8, ["onClick"])
      ]),
      _: 1
    }, 8, ["showModal"])
  ]))
}