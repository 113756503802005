import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import { defineComponent } from 'vue';

import HomeView from '../views/HomeView.vue';
import ProducerProjects from '@/views/ProducerProjects.vue';
import ProducerView from '@/views/ProducerView.vue';
import OperatorScreen from '@/views/OperatorScreen.vue';
import LoginDirector from '@/views/LoginDirector.vue';
import RolesView from '@/views/RolesView.vue';
import VideoEditor from '@/views/VideoEditorView.vue';

import TestRecorder from '@/views/tests/recorder.vue';
import TestDirector from '@/views/tests/director.vue';
import TestOperator from '@/views/tests/operator.vue';
import TestWebRTC from '@/views/tests/webrtc.vue';
import TestFrames from '@/views/tests/frames.vue';
import TestMock from '@/views/tests/mock.vue';
import TestEditingList from '@/views/tests/editing.vue';
import TestCodecs from '@/views/tests/codecs.vue';

import { api } from '@/utils/api';
import appEvents from '@/utils/events';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
  },
  {
    path: '/producer-projects',
    name: 'producer-projects',
    component: ProducerProjects,
  },
  {
    path: '/director',
    name: 'director',
    meta: {
      auth: 'director',
    },
    component: ProducerView,
  },
  {
    path: '/operator/:camera?',
    name: 'operator',
    component: OperatorScreen,
    meta: {
      auth: 'operator',
    },
  },
  {
    path: '/roles',
    name: 'roles',
    component: RolesView,
  },

  // tests
  {
    path: '/test/codecs',
    name: 'test-codecs',
    component: TestCodecs,
  },
  {
    path: '/test/webrtc',
    name: 'test-webrtc',
    component: TestWebRTC,
  },
  {
    path: '/test/recorder',
    name: 'test-recorder',
    component: TestRecorder,
  },
  {
    path: '/test/frames',
    name: 'test-frames',
    component: TestFrames,
  },
  {
    path: '/test/editinglist',
    name: 'test-editinglist',
    component: TestEditingList,
  },
  // УДАЛИТЬ
  {
    path: '/test/director',
    name: 'test-director',
    component: TestDirector,
    meta: {
      auth: 'director',
    },
  },
  {
    path: '/test/operator/:camera',
    name: 'test-operator',
    component: TestOperator,
    meta: {
      auth: 'operator',
    },
  },
  // УДАЛИТЬ ПОСЛЕ СЛИЯНИЯ
  {
    path: '/test/login',
    name: 'test-login',
    component: TestMock,
    meta: {
      mock: 'SSO Login',
    },
  },
  {
    path: '/video-editor/:projectId?',
    name: 'video-editor',
    component: VideoEditor,
  },
  {
    path: '/login',
    name: 'login-director',
    component: LoginDirector,
    beforeEnter: async (to) => {
      if (!to.query.token) return '/';
      if (process.env.VUE_APP_AUTH !== '' && to.query.token === 'env') {
        to.query.token = process.env.VUE_APP_AUTH;
      }
      const am = await api().setAppAuth(to.query.token as string);
      if (am === null) return '/bad-login';
      return '/roles';
    },
  },
  {
    path: '/test/operator-login',
    name: 'test-operator-login',
    component: TestMock,
    meta: {
      mock: 'Operator Login',
    },
  },
  {
    path: '/qr/:code',
    name: 'qrcode-auth',
    beforeEnter: async (to) => {
      const data = await api().checkQrCode(to.params.code as string);
      if (data === null) {
        api().resetProjectAuth();
        return '/bad-qr-code';
      }
      api().setProjectAuth(`${data.id}:${data.auth}`, data.camera);
      return {
        name: 'operator',
        params: {
          camera: data.camera,
        },
      };
    },
    meta: {
      auth: 'none',
    },
    component: defineComponent({}), // чисто что бы было
  },
  // {
  //   path: '/login',
  //   name: 'login-callback',
  // beforeEnter: async (to) => {
  //   if (!to.query.token) return '/bad-auth-token';
  //   if (process.env.VUE_APP_AUTH !== '' && to.query.token === 'env') {
  //     to.query.token = process.env.VUE_APP_AUTH;
  //   }
  //   const am = await api().setAppAuth(to.query.token as string);
  //   console.log(am);
  //   if (am === null) return '/bad-login';
  //   return '/';
  // },
  //   component: defineComponent({}), // чисто что бы было
  // },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  // history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const savedAuth = JSON.parse(window.localStorage.getItem('app.auth') || 'null');

  appEvents.emit('app:checkVersion');

  switch (to.meta.auth) {
    case 'director':
      if (api().isFullAuthorized()) return next();
      else return next({ name: 'home' });
      break;
    case undefined:
      if (!savedAuth && to.path !== '/login') {
        if (to.path === '/') return next();
        return next({ name: 'home' });
      }
      return next();
      break;
    case 'none':
    case 'operator':
      next();
      return;
    default:
      throw new Error(`Unknown auth type: ${to.meta.auth}`);
      return;
  }
});

export default router;
