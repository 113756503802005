import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-57963cf3"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "video-frames" }
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "video-frames__list",
      onMousemove: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.mousemove && _ctx.mousemove(...args))),
      onMouseup: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.mouseup && _ctx.mouseup(...args)))
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.layers, (layer, index) => {
        return (_openBlock(), _createElementBlock("div", {
          key: index,
          class: "video-frames__layer"
        }, [
          _createElementVNode("div", {
            class: "video-frames__drag video-frames__drag_left",
            onMousedown: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.mousedown && _ctx.mousedown(...args))),
            style: _normalizeStyle(`width: ${_ctx.positionStart?.x}px`)
          }, null, 36),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(layer, (item, indexFrame) => {
            return (_openBlock(), _createElementBlock("img", {
              class: "video-frames__image",
              key: `frame-${indexFrame}`,
              src: item
            }, null, 8, _hoisted_2))
          }), 128)),
          _createElementVNode("div", {
            class: "video-frames__drag video-frames__drag_right",
            onMousedown: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.mousedown && _ctx.mousedown(...args)))
          }, null, 32)
        ]))
      }), 128))
    ], 32)
  ]))
}