
import { defineComponent, onMounted, ref } from 'vue';
import ModalError from '@/components/modals/ModalError.vue';
import { useSize } from '@/composables/useSize';
import { useSupportBrowser } from '@/composables/useSupportBrowser';
import Modal from './components/common/Modal.vue';
import ButtonUser from './components/common/buttons/Button.vue';
// import pwa from './components/pwa.vue';

export default defineComponent({
  components: { ModalError, Modal, ButtonUser },
  setup() {
    const { setVarWindowHeight } = useSize();
    const { checkSupportBrowser, checkWebView, listErrorSupport, checkIsSupportVersionChrome } = useSupportBrowser();
    const isShowMadalSupportError = ref(false);
    const isShowBtnShare = ref(false);
    const isSupportVersion = ref(true);

    const registration: any = ref(null);
    const updateExists = ref(false);
    const refreshing = ref(false);

    const updateAvailable = (event: any) => {
      registration.value = event.detail;
      updateExists.value = true;
    };

    const refreshApp = () => {
      updateExists.value = false;
      if (!registration.value || !registration.value.waiting) return;
      registration.value.waiting.postMessage({ type: 'SKIP_WAITING' });
    };

    onMounted(() => {
      isSupportVersion.value = checkIsSupportVersionChrome();
      setVarWindowHeight();
      if (checkWebView() || checkSupportBrowser()) isShowMadalSupportError.value = true;
      if ('canShare' in navigator) isShowBtnShare.value = true;
      document.addEventListener('swUpdated', updateAvailable, { once: true });
      navigator.serviceWorker.addEventListener('controllerchange', () => {
        if (refreshing.value) return;
        refreshing.value = true;
        window.location.reload();
      });
    });

    const shareGoogle = async () => {
      try {
        await navigator.share({ url: `${location.origin}` });
      } catch (err) {
        console.log(err);
      }
    };

    return {
      shareGoogle,
      isShowMadalSupportError,
      isShowBtnShare,
      listErrorSupport,
      isSupportVersion,
      updateExists,
      refreshApp,
    };
  },
});
