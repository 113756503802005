import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-366cf7f3"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "menu-effects" }
const _hoisted_2 = { class: "menu-effects-transitions menu-effects__container" }
const _hoisted_3 = {
  key: 0,
  class: "menu-effects__list menu-effects__transitions"
}
const _hoisted_4 = { class: "menu-effects-colors menu-effects__container" }
const _hoisted_5 = {
  key: 0,
  class: "menu-effects__list menu-effects__list_colors"
}
const _hoisted_6 = {
  key: 0,
  class: "menu-effects__line-menu"
}
const _hoisted_7 = { class: "menu-effects__buttons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_Transition, {
        name: `${!_ctx.isOpenColors ? 'side' : ''}`
      }, {
        default: _withCtx(() => [
          (_ctx.isOpenTransition)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createVNode(_component_Icon, {
                  class: "menu-effects__icon",
                  icon: "trnasition5"
                }),
                _createVNode(_component_Icon, {
                  class: "menu-effects__icon",
                  icon: "trnasition4"
                }),
                _createVNode(_component_Icon, {
                  class: "menu-effects__icon",
                  icon: "trnasition3"
                }),
                _createVNode(_component_Icon, {
                  class: "menu-effects__icon",
                  icon: "trnasition2"
                }),
                _createVNode(_component_Icon, {
                  class: "menu-effects__icon",
                  icon: "trnasition1"
                })
              ]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["name"])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_Transition, {
        name: `${!_ctx.isOpenTransition ? 'side' : ''}`
      }, {
        default: _withCtx(() => [
          (_ctx.isOpenColors)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createVNode(_component_Icon, {
                  class: "menu-effects__icon",
                  icon: "color5"
                }),
                _createVNode(_component_Icon, {
                  class: "menu-effects__icon",
                  icon: "color4"
                }),
                _createVNode(_component_Icon, {
                  class: "menu-effects__icon",
                  icon: "color3"
                }),
                _createVNode(_component_Icon, {
                  class: "menu-effects__icon",
                  icon: "color2"
                }),
                _createVNode(_component_Icon, {
                  class: "menu-effects__icon",
                  icon: "color1"
                })
              ]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["name"])
    ]),
    (_ctx.isOpenTransition || _ctx.isOpenColors)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_7, [
      _createVNode(_component_Icon, {
        onClick: _ctx.openTransitions,
        class: _normalizeClass({ active: _ctx.isOpenTransition }),
        icon: "transition-group"
      }, null, 8, ["onClick", "class"]),
      _createVNode(_component_Icon, {
        onClick: _ctx.openColors,
        class: _normalizeClass({ active: _ctx.isOpenColors }),
        icon: "effect"
      }, null, 8, ["onClick", "class"])
    ])
  ]))
}