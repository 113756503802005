
import { defineComponent, computed, Ref, ref, watch, onMounted, toRefs } from 'vue';
// import { useStore } from "vuex";
import { Position, useMouseDragForTrim } from '@/composables/mouseDrag';
// import { useVideoEdit } from "@/composables/useVideoEdit";
// import { videoEditor } from '@/helpers/videoEditor';
import { getVideoFrames } from '@/utils/videoEditor';

export default defineComponent({
  name: 'VideoFrames',
  components: {},
  props: {
    videos: {
      type: Array<string>,
      default: () => [],
    },
    duration: {
      type: Number,
      default: 0,
    },
  },
  setup(props) {
    const { curentPosition, mousedown, mousemove, mouseup, dragElem } = useMouseDragForTrim();
    const { videos, duration } = toRefs(props);

    const layers = ref<string[][]>([]);

    onMounted(async () => {
      for (const [index, item] of videos.value.entries()) {
        layers.value[index] = await getVideoFrames(item, duration.value);
      }
    });

    const startTime = ref(0);
    const endTime = ref(0);

    watch(curentPosition, (value) => {
      if (dragElem.value.classList.contains('video-frames__drag_left')) {
        startTime.value = (positionStart.value?.x || 0) / 200;
        // setStartTimeLayers(startTime.value);
        positionStart.value = value;
      } else if (dragElem.value.classList.contains('video-frames__drag_right')) {
        positionEnd.value = value;
        endTime.value = (positionEnd.value?.x || 0) / 200;
      }
    });

    const positionStart: Ref<Position | null> = ref(null);
    const positionEnd: Ref<Position | null> = ref(null);

    return {
      layers,
      curentPosition,
      mousedown,
      mousemove,
      mouseup,
      dragElem,
      positionStart,
      positionEnd,
      startTime,
      endTime,
    };
  },
});
