
import { defineComponent, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import Icon from '@/components/common/icon/Icon.vue';
import AppVersion from '@/components/common/AppVersion.vue';
import { useStore } from 'vuex';

export default defineComponent({
  name: 'HomeView',
  components: { Icon, AppVersion },
  setup() {
    const router = useRouter();
    const store = useStore();

    onMounted(() => {
      if (store.state.auth.loggedUser) {
        router.push('/roles');
      }
    });
    const login = async () => {
      window.location.replace('/api/login/google');
    };
    return {
      login,
    };
  },
});
