
import { defineComponent, ref, Ref, onMounted, computed, ComputedRef } from 'vue';
import { useStore } from 'vuex';
import PerfectScrollbar from 'perfect-scrollbar';
import 'perfect-scrollbar/css/perfect-scrollbar.css';
import VideoFrames from './VideoFrames.vue';
// import { videoEditor } from '@/helpers/videoEditor';

export default defineComponent({
  name: 'VideoTimeline',
  components: {
    VideoFrames,
  },
  props: {
    videos: {
      type: Array<string>,
      default: () => [],
    },
  },
  setup() {
    const store = useStore();
    const timelineContainer: Ref<HTMLElement | null> = ref(null);
    const ps: any = ref(null);
    // const { moiveDuration, movie } = useVideoEdit();

    const currentPosition = computed(() => {
      return ((currentTime.value || 0) * 200) / 2;
    });

    const currentTime: ComputedRef<number> = computed(() => {
      return store.state.video.currentTime;
    });

    const moiveDuration = computed(() => {
      return store.state.video.duration / 1000 || 100;
    });

    // const

    const timelineLength = computed(() => {
      let arr = [];
      for (let index = 0; index < Math.ceil(moiveDuration.value / 2); index++) {
        const ms = store.state.video.duration;
        const minutes = Math.floor(ms / 1000 / 60);
        store.dispatch('video/getDuration', ms);
        arr[index] = {
          m: minutes < 10 ? `0${minutes}` : minutes,
          s: index * 2 < 10 ? `0${index * 2}` : index * 2,
        };
      }
      return arr;
    });

    const linearEl: Ref<HTMLElement | null> = ref(null);

    const setVideoTime = (event: any) => {
      const time: number = (event.pageX - (linearEl?.value?.getBoundingClientRect().left || 0)) / 200;
      // videoEditor.setTimeMovie(time);
    };

    onMounted(() => {
      if (timelineContainer.value) {
        ps.value = new PerfectScrollbar(timelineContainer.value, {
          wheelPropagation: false,
        });
      }
    });

    return {
      store,
      timelineContainer,
      moiveDuration,
      currentPosition,
      linearEl,
      setVideoTime,
      timelineLength,
      currentTime,
    };
  },
});
